import {
  GetDataGroup,
  getDataTukang,
} from "../../../actions/datamaster_action";

import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderField,
  ReanderSelect,
  getTglSystem,
  Tabel,
  getData2,
  ToastNotification,
  getDataNoTimeOut2,
  setItem,
  Gramasi,
  Loading,
  getItem,
} from "../../../components/helpers/library";
import ValidasiPesanan from "../../../Validasi/Pesanan/ValidasiPesanan";

class HeadStockOpnamePesanan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      disabled: false,
      columns: [
        {
          dataField: "no_pesanan",
          text: "No Pesanan",
          footer: "",
          footerAttrs: {
            colSpan: "3",
          },
        },
        {
          dataField: "kode_tukang",
          text: "Tukang",
        },
        {
          dataField: "kode_group",
          text: "Kode Group",
        },
        {
          dataField: "berat_selesai",
          text: "Berat Selesai",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right">{cell.toFixed(2)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}
              </div>
            );
          },
        },
        {
          dataField: "berat_susut",
          text: "Berat Susut",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right">{cell.toFixed(2)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}
              </div>
            );
          },
        },
        {
          dataField: "ongkos",
          text: "Ongkos",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">
                {parseInt(cell).toLocaleString("kr-KO")}
              </div>
            );
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {parseInt(
                  columnData.reduce((acc, item) => acc + item, 0)
                ).toLocaleString("kr-KO")}
              </div>
            );
          },
        },
      ],
    };
    this.cekData = this.cekData.bind(this);
    // this.print = this.print.bind(this);
  }

  componentWillUnmount() {
    setItem("data_stock_opname_pesanan", []);
  }
  componentDidMount() {
    this.props.dispatch(getDataTukang());
    this.props.dispatch(GetDataGroup());
    getData2("stock-opname/no-stocks")
      .then((res) => {
        // console.log(res);
        this.props.change("no_so_pesanan", res.data.no_so_pesanan);
      })
      .catch((res) => {
        console.log("No Pesanan So Gagal Ke Get");
      });
  }
  cekData() {
    this.setState({
      isLoading: true,
    });

    if (this.state.kode_tukang === undefined) {
      ToastNotification("info", "Data Tukang Harus Dipilih");
      this.setState({
        isLoading: false,
      });
      return false;
    }
    if (this.state.kategori === undefined) {
      this.setState({
        isLoading: false,
      });
      ToastNotification("info", "Kategori Harus Dipilih");
      return false;
    }
    getDataNoTimeOut2(
      `stock-opname?kode_group=${this.state.kategori}&kode_tukang=${this.state.kode_tukang}`
    )
      .then((res) => {

        let berat_sisa = res.data.data_so.reduce(
          (a, b) => parseFloat(a) + parseFloat(b.berat_selesai),
          0
        );
        let berat_susut = res.data.data_so.reduce(
          (a, b) => parseFloat(a) + parseFloat(b.berat_susut),
          0
        );
        let hasil =
          parseFloat(res.data.saldo_awal) -
          (parseFloat(berat_sisa) + parseFloat(berat_susut));
        this.props.change("berat_sisa", hasil);
        this.props.change("total_awal_bahan", res.data.saldo_awal);
        setItem("data_stock_opname_pesanan", res.data.data_so);
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        setItem("data_stock_opname_pesanan", []);
        this.setState({
          isLoading: false,
        });

        ToastNotification(
          "info",
          err.response?.data.message || "Terjadi Kesalahaan Saat Request Data"
        );
      });
  }
  cekSelisih(e) {
    let berat_sisa = document.getElementById("berat_sisa").value || 0;
    let hasil = parseFloat(berat_sisa) - parseFloat(e || 0);
    if (e === "") {
      this.props.change("seleisih", parseFloat(0));
      return false;
    }
    if (parseFloat(e) > parseFloat(berat_sisa)) {
      ToastNotification("info", "Total Setor Bahan Melebihi Saldo Awal ");
      this.setState({
        disabled: true,
      });
      this.props.change("seleisih", parseFloat(0));
      return false;
    } else {
        this.props.change("seleisih", parseFloat(hasil).toFixed(3));
        this.setState({
          disabled: false,
        });
    }

    //  if(parseInt(e) )
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-3">
            <Field
              id="no_so_pesanan"
              name="no_so_pesanan"
              label="No So Pesanan"
              placeholder="No So Pesanan"
              readOnly
              component={ReanderField}
            />
          </div>

          <div className="col-3">
            <Field
              id="tgl_so"
              name="tgl_so"
              label="Tgl So"
              placeholder="Tgl So"
              readOnly
              component={ReanderField}
            />
          </div>
          <div className="col-3">
            <Field
              id="tukang"
              name="kode_tukang"
              label="Tukang"
              placeholder="Tukang"
              onChange={(e) => {
                this.setState({ kode_tukang: e });
                setTimeout(() => {
                  this.cekData();
                }, 100);
              }}
              options={this.props.DataTukang.map((list) => {
                let row = {
                  value: list.kode_tukang,
                  name: list.kode_tukang,
                };
                return row;
              })}
              component={ReanderSelect}
            />
          </div>
          <div className="col-3">
            <Field
              id="kategori"
              name="kategori"
              label="Kategori"
              placeholder="Kategori"
              onChange={(e) => {
                this.setState({ kategori: e });
                setTimeout(() => {
                  this.cekData();
                }, 100);
              }}
              options={this.props.DataGroup.map((list) => {
                let row = {
                  value: list.kode_group,
                  name: list.kode_group,
                };
                return row;
              })}
              component={ReanderSelect}
            />
          </div>

          <div className="col-12">
            <Tabel
              keyField="_id"
              exportCsv={false}
              data={getItem("data_stock_opname_pesanan") || []}
              columns={this.state.columns}
              empty={true}
              pagination="off"
              textEmpty="Data Pesanan Kosong"
            />
          </div>
          <div className="col-4"></div>
          <div className="col-2">
            <Field
              id="berat_sisa"
              readOnly
              name="berat_sisa"
              label="Berat Sisa"
              normalize={Gramasi}
              placeholder="Berat Sisa"
              component={ReanderField}
            />
          </div>
          <div className="col-2">
            <Field
              id="total_awal_bahan"
              readOnly
              name="total_awal_bahan"
              label="Saldo Awal"
              normalize={Gramasi}
              placeholder="Saldo Awal"
              component={ReanderField}
            />
          </div>
          <div className="col-2">
            <Field
              id="total_setor_bahan"
              name="total_setor_bahan"
              normalize={Gramasi}
              label="Total Setor Bahan"
              onChange={(e) => this.cekSelisih(e.target.value)}
              placeholder="Total Setor Bahan"
              component={ReanderField}
            />
          </div>
          <div className="col-2">
            <Field
              id="seleisih"
              readOnly
              name="seleisih"
              label="Selisih"
              placeholder="Selisih"
              component={ReanderField}
            />
          </div>
          <div className="col-12 text-right">
            <button
              className="btn btn-primary"
              disabled={this.props.isLoading || this.state.disabled}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.state.isLoading && (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        )}
      </form>
    );
  }
}

HeadStockOpnamePesanan = reduxForm({
  form: "HeadStockOpnamePesanan",
  enableReinitialize: true,
  validate: ValidasiPesanan,
})(HeadStockOpnamePesanan);
export default connect((state) => {
  return {
    DataTukang: state.datamaster.getDataTukang,
    DataGroup: state.datamaster.GetDataGroup,
    initialValues: {
      tgl_so: getTglSystem(),
    },
  };
})(HeadStockOpnamePesanan);
