import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanKirimLabelingExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan KIRIM STOCK"
          sheet="Laporan KIRIM STOCK"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="9" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN KIRIM STOCK{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> Periode </th>
            </tr>
            <tr>
              <th colSpan="9">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="9"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="9"></th>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TANGGAL
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO KIRIM STOCK
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO TERIMA SEPUH
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
               KODE BAKI
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                JENIS
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
               QTY
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY KEMBALI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT KEMBALI
              </td>
            
            </tr>
          </thead>
          {this.props.data.map((item, index) => (
            <>
              <tbody>
                {item.detail.map((row, index) => (
                  <>
                    <tr>
                      <td colSpan="9" style={{ textAlign: "left", border : "3px" }}>
                        No Faktur : {item._id}
                      </td>
                    </tr>
                    <tr>
                      <td>{row.tanggal}</td>
                      <td>{row.no_kirim_stock}</td>
                      <td>{row.no_terima_sepuh}</td>
                      <td>{row.kode_lokasi_toko}</td>
                      <td>{row.kode_dept}</td>
                      <td style={{ textAlign: "right" }}>{row.qty}</td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.qty_kembali !== undefined ? row.qty_kembali : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat_kembali !== undefined
                          ? row.berat_kembali.toFixed(2)
                          : 0}
                      </td>
                     
                    </tr>
                  </>
                ))}
              </tbody>
            </>
          ))}
          <tfoot>
            <tr>
              <td
                colSpan="5"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Sub Total
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
               {this.props.data.reduce((acc, item) => acc + item.detail.reduce((a, b) => a + b.qty, 0),0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
               {this.props.data.reduce((acc, item) => acc + item.detail.reduce((a, b) => a + b.berat, 0),0).toFixed(2)}

              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
               {this.props.data.reduce((acc, item) => acc + item.detail.reduce((a, b) => a + b.qty_kembali, 0),0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
               {this.props.data.reduce((acc, item) => acc + item.detail.reduce((a, b) => a + b.berat_kembali, 0),0).toFixed(2)}
                
              </td>
             
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanKirimLabelingExel;
