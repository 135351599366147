import {
  GetDataGroup,
  getDataTukang,
} from "../../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  ReanderSelect,
  Field,
  ReanderField,
  getDataNoTimeOut2,
  Loading,
  NumberOnly,
} from "../../../../components/helpers/library";

const maptostate = (state) => {
  return {
    DataGroup: state.datamaster.GetDataGroup,
    DataTukang: state.datamaster.getDataTukang,
  };
};
class FormBahanTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataTukang());
    this.props.dispatch(GetDataGroup());
  }
  getBahan(e) {
    this.setState({ isLoading: true });
    getDataNoTimeOut2(
      `bahan?jenis=${this.state.jenis}&kode_group=${e}&kode_tukang=${this.state.kode_tukang}`
    )
      .then((res) => {
        if (res.data.bahan === null) {
          this.props.change("sisa_bahan", 0);
        } else {
          this.props.change("sisa_bahan", res.data.bahan.jml_bahan.toFixed(3));
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-4">
            <Field
              id="kode_tukang"
              name="kode_tukang"
              onChange={(e) => this.setState({ kode_tukang: e })}
              component={ReanderSelect}
              type="text"
              options={this.props.DataTukang.filter(
                (list) => list.kode_tukang !== undefined
              ).map((list) => {
                let data = {
                  value: list.kode_tukang,
                  name: list.kode_tukang,
                };
                return data;
              })}
              label="Kode Tukang"
              placeholder="Silahkan Pilih Kode Tukang"
            />
          </div>
          <div className="col-4">
            <Field
              id="jenis"
              name="jenis"
              component={ReanderSelect}
              type="text"
              onChange={(e) => this.setState({ jenis: e })}
              options={[
                {
                  name: "PATRI",
                  value: "PATRI",
                },
                {
                  name: "BAHAN",
                  value: "BAHAN",
                },
              ]}
              label="Jenis"
              placeholder="Silahkan Pilih Jenis"
            />
          </div>
          <div className="col-4">
            <Field
              id="kategori"
              name="kategori"
              component={ReanderSelect}
              type="text"
              options={this.props.DataGroup.map((list) => {
                let row = {
                  name: list.kode_group,
                  value: list.kode_group,
                };
                return row;
              })}
              onChange={(e) => this.getBahan(e)}
              label="Kategori"
              placeholder="Silahkan Pilih Kategori"
            />
          </div>
          <div className="col-4">
            <Field
              id="sisa_bahan"
              name="sisa_bahan"
              component={ReanderField}
              readOnly={true}
              type="text"
              label="Sisa Bahan / Gr"
              placeholder="Silahkan Masukan Sisa Bahan / Gr"
            />
          </div>
          <div className="col-4">
            <Field
              id="tambah_bahan"
              name="tambah_bahan"
              component={ReanderField}
              type="text"
              normalize={NumberOnly}
              label="Tambah Bahan / Gr"
              placeholder="Silahkan Masukan Tambah Bahan / Gr"
            />
          </div>
          <div className="col-4">
            <Field
              id="keterangan"
              name="keterangan"
              component={ReanderField}
              type="text"
              label="Keterangan"
              placeholder="Silahkan Masukan Keterangan"
            />
          </div>
          <div className="col-12 text-right">
            <button type="submit" className="btn btn-primary">
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.state.isLoading || this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormBahanTukang = reduxForm({
  form: "FormBahanTukang",
  enableReinitialize: true,
})(FormBahanTukang);
export default connect(maptostate)(FormBahanTukang);
