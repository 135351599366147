import {React, connect,Tabel,hideModal,Link, reduxForm, reset,isEdit, showModal, ModalGlobal,Swal,uuidv4,moment,Panel, PanelHeader } from "../../../components/helpers/library";
import {
  formatDate,
  getItem,
  setItem,
} from "../../../components/helpers/function.jsx";
import HeadDetailKirim from "./HeadDetailKirim.jsx";
import DetailBarang from "./DetailBarang.jsx";
import { shoModalTambahBarang } from "../../../actions/KirimBarangDariBos_action.jsx";
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";



class KirimBarangSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingTambahDetailKirim: false,
      isLoadingReset: false,
      isLoadingTambahBarang: false,
      isEdit: false,
      isLoadingSimpanDataKirim: false,
      isTourOpen: false,
      isShowingMore: false,
      form: "",
      columns: [
        {
          dataField: "jenis",
          text: "Jenis Barang",
          footer: "Total",
        },
        {
          dataField: "berat",
          text: "Berat",
          formatter: (cell) => {
            return <span>{cell.toFixed(3)}</span>;
          },
          footer: (columnData) =>
            columnData.reduce((acc, item) => acc + item, 0).toFixed(3),
        },
        {
          dataField: "qty",
          text: "Qty",
          footer: (columnData) =>
            columnData.reduce((acc, item) => acc + item, 0),
        },
        {
          dataField: "asal_barang",
          text: "Asal Barang",
          footer: "",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            let data = {
              id : row.id,
              kategori: row.kategori,
              jenis: row.jenis,
              asal_barang: row.asal_barang,
              berat: parseFloat(row.berat),
              qty: parseInt(row.qty),
              index : index
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button type="button"
                    onClick={() => this.editData(data)}
                    className="btn btn-warning mr-3">
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => this.hapusdata(index)}
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
          footer: "",
        },
      ],
    };
    this.submitDetailKirim = this.submitDetailKirim.bind(this);
    this.showModalDetailKirim = this.showModalDetailKirim.bind(this);
    this.showModalTambahBarang = this.showModalTambahBarang.bind(this);
    this.tambahDataBarang = this.tambahDataBarang.bind(this);
  }
  editData(data) {
    this.setState({
      isEdit : true
    })
    this.props.dispatch(shoModalTambahBarang(data));
    this.props.dispatch(showModal());
  }
  submitDetailKirim(data) {
    // console.log(data);
    let row = {
      tanggal_kirim: formatDate(
        moment(new Date(data.tanggal_kirim)).format("YYYY-MM-DD")
      ),
      tujuan: data.tujuan,
    };
    this.setState({
      isLoadingTambahDetailKirim: true,
    });

    this.props.dispatch(reset("HeadDetailKirim"));
    setTimeout(() => {
      setItem("data_detail_kirim_barang_sepuh", row);
      this.setState({
        isLoadingTambahDetailKirim: false,
      });
      this.props.dispatch(hideModal());
    }, 200);
  }
  showModalDetailKirim() {
    this.props.dispatch(showModal());
    this.setState({
      form: "INPUT_DETAIL_KIRIM",
    });
  }
  hapusdata(id) {
    Swal.fire({
      title: "Anda Yakin !!",
      text: "Ingin Menghapus Data Ini ?",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        let data = getItem("databarang_sepuh");
        data.splice(id, 1);
        setItem("databarang_sepuh", data);
        this.setState({
          hapus : 'berhasil'
        })
      }
    });
  }
  showModalTambahBarang() {
    this.props.dispatch(showModal());
    this.props.dispatch(isEdit(false))
    this.props.dispatch(shoModalTambahBarang(false));
    this.props.dispatch(showModal());
    this.setState({
      form: "INPUT_DATA_BARANG",
    });
  }
  reset() {
    this.setState({
      isLoadingReset: true,
    });
    setTimeout(() => {
      setItem("data_detail_kirim_barang_sepuh", []);
      this.setState({
        isLoadingReset: false,
      });
      this.props.dispatch(reset("HeadDetailKirim"));
    }, 200);
  }
  tambahDataBarang(data) {
    this.setState({ isLoadingTambahBarang: true });
    setTimeout(() => {
      let row = {
        id : uuidv4(),
        kategori: data.kategori,
        jenis: data.jenis,
        asal_barang: data.asal_barang,
        berat: parseFloat(data.berat),
        qty: parseInt(data.qty),
      };
      let cek = getItem("databarang_sepuh").length === 0 ? [] : getItem("databarang_sepuh");
      let hasil_cek = cek.findIndex((hasil) => hasil.id === 0);

      if (hasil_cek === -1) {
        if (cek.length > data.index) {
          cek[data.index].kategori = data.kategori;
          cek[data.index].jenis = data.jenis;
          cek[data.index].asal_barang = data.asal_barang;
          cek[data.index].berat = parseFloat(data.berat);
          cek[data.index].qty = parseInt(data.qty);
          setItem("databarang_sepuh", cek);
          this.props.dispatch(hideModal());
        }else{
          cek.push(row);
          setItem("databarang_sepuh", cek);
        }
      } 

      this.setState({ isLoadingTambahBarang: false });
      this.props.dispatch(reset("DetailBarang"));
    }, 200);
  }

  
  // disableBody = (target) => disableBodyScroll(target);
  // enableBody = (target) => enableBodyScroll(target);

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Stock Pembelian</Link>
          </li>
          <li className="breadcrumb-item active">
            Kirim Sepuh Dari Barang Bos
          </li>
        </ol>
        <h1 className="page-header">Kirim Sepuh Dari Barang Bos </h1>
        <Panel>
          <PanelHeader>Kirim Sepuh Dari Barang Bos</PanelHeader>
          <br />
          {/* Kirim Dari Barang Bos */}
          <div className="container">
            <div className="row">
              <div className="col-7">
                Tanggal Kirim :{" "}
                {getItem("data_detail_kirim_barang_sepuh").tanggal_kirim} <br />
                Tujuan : {getItem("data_detail_kirim_barang_sepuh").tujuan}{" "}
                <br />
              </div>
              <div className="col-5 text-right">
                <button
                  data-tut="reactour__tambahdetailkirim"
                  onClick={() => this.showModalDetailKirim()}
                  className="btn btn-primary"
                >
                  {getItem("data_detail_kirim_barang_sepuh").tanggal_kirim
                    ? "Edit Data Detail Kirim"
                    : "Tambah Data Detail Kirim"}
                </button>{" "}
                &nbsp;
                <button
                  data-tut="reactour__tambahbarang"
                  onClick={() => this.showModalTambahBarang()}
                  className="btn btn-success"
                >
                  {" "}
                  Tambah Data Barang{" "}
                </button>
              </div>
            </div>
            &nbsp;
            <Tabel
              keyField="_id"
              data={getItem("databarang_sepuh") || []}
              columns={this.state.columns}
              empty={getItem("databarang_sepuh") || []}
              textEmpty="Data Barang Kosong"
            />
            <div className="row">
              <div className="col-5 text-left">
                <button
                  data-tut="simpandatakirim"
                  className="btn btn-primary"> Simpan Data Kirim </button>{" "}
                &nbsp;
                <button
                  data-tut="resetData"
                  className="btn btn-warning"
                  onClick={() => this.reset()}
                >
                  {" "}
                  Reset{" "}
                </button>
              </div>
            </div>
          </div>
          <br />
          {/* End Tambah Kirim Barang Bos  */}
          <ModalGlobal
            size="P"
            title={
              this.state.form === "INPUT_DETAIL_KIRIM"
                ? "Form Input Detail Kirim"
                : "Form Input Detail Barang"
            }
            content={
              this.state.form === "INPUT_DETAIL_KIRIM" ? (
                <HeadDetailKirim
                  isLoadingTambahDetailKirim={
                    this.state.isLoadingTambahDetailKirim
                  }
                  onSubmit={(data) => this.submitDetailKirim(data)}
                />
              ) : (
                <DetailBarang
                  isEdit={this.state.isEdit}
                  isLoadingTambahBarang={this.state.isLoadingTambahBarang}
                  onSubmit={(data) => this.tambahDataBarang(data)}
                />
              )
            }
          />
        </Panel>
        
      </div>
    );
  }
}

KirimBarangSepuh = reduxForm({
  form: "KirimBarangSepuh",
  enableReinitialize: true,
})(KirimBarangSepuh);
export default connect()(KirimBarangSepuh);
