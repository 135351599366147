import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  localStorageencryp,
  formatDate,
  moment,
  getDataNoTimeOut2,
} from "../../../../components/helpers/library";
import {
  ToastNotification,
} from "../../../../components/helpers/notification";
import HeadLaporanPesananTukang from "./HeadLaporanPesananTukang";

class LaporanPesananTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datalappesanantukang: [],
      isLoading: false,
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({ isLoading: true });
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      type_laporan: data.type,
    };
    console.log(data);
    getDataNoTimeOut2(
      `pembagian-pesanan/reports?kode_group=${data.kategori}&kode_tukang=${data.kode_tukang}&tgl_from=${data_post.tgl_awal}&tgl_to=${data_post.tgl_akhir}&status_proses=${data.type}`
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.length === 0) {
          ToastNotification("info", "Data Laporan Kosong!");
          this.setState({
            export: false,
            isLoading: false,
            databahantukang: [],
          });
        } else {
          ToastNotification("success", "Laporan Bahan Tukang Detail Tersedia");
          localStorageencryp(
            "tgl_laporan_pesanan_tukang",
            JSON.stringify(data_post)
          );
          let datapesanantukang = [];
          res.data.forEach(element => {
            let row = {
              no_persekot: element.no_pesanan,
              tgl_system: element.tanggal,
              tgl_selesai: element.tanggal_selesai || '-',
              nama_customer: element.nama_customer,
              nama_barang: element.nama_barang,
              nama_atribut: element.atribut,
              berat_atr: element.berat_atribut,
              berat_cad: 0,
              ongkos: element.ongkos_tukang,
              ongkos_customer: element.ongkos_customer,
              berat_customer: element.berat_customer,
              berat_tukang: element.berat_tukang || 0,
              susut_tukang: element.susut_tukang,
              asal_bahan: element.asal_bahan || 0,
              saldo_bahan: element.saldo_bahan || 0,
              status: data.type
            };
            datapesanantukang.push(row);
          });
          this.setState({ 
            isLoading: false,
            export: true,
            datalappesanantukang: datapesanantukang,
          });
        }
      })
      .catch((err) => console.log(err));
    // setTimeout(() => {
    //   this.setState({ isLoading: false, export: true });
    //   NotifSucces("Berhasil Melihat Data");
    // }, 5000);
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Pesanan Tukang</li>
        </ol>
        <h1 className="page-header">Laporan Pesanan Tukang </h1>
        <Panel>
          <PanelHeader>Laporan Pesanan Tukang</PanelHeader>
          <br />
          {/* Laporan Pesanan Tukang */}
          <PanelBody>
            <HeadLaporanPesananTukang
              onSubmit={(data) => this.handleSubmit(data)}
              isLoading={this.state.isLoading}
              export={this.state.export}
              LaporanPesanan={this.state.datalappesanantukang}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanPesananTukang = reduxForm({
  form: "LaporanPesananTukang",
  enableReinitialize: true,
})(LaporanPesananTukang);
export default connect()(LaporanPesananTukang);
