import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  localStoragedecryp,
  formatGram,
  getUserdata,
} from "../../../../../components/helpers/function";
class LaporanKirimBarangExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Kirim Barang"
          sheet="Laporan Kirim Barang"
          buttonText="Export Exel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="7"> Laporan Kirim Barang </th>
            </tr>
            <tr>
              <th colSpan="7"> Periode </th>
            </tr>
            <tr>
              <th colSpan="7">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_from +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_to}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="7"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="7"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="7"></th>
            </tr>
            <tr>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO KIRIM
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE TOKO
              </th>

              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE BARCODE
              </th>

              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE GROUP
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE JENIS
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NAMA BARANG
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TIPE BARANG
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((item, index) => (
                <tr key={index}>
                <td> {item.no_kirim}</td>
                <td> {item.kode_toko}</td>
                <td>&nbsp;{item.kode_barcode}</td>
                <td> {item.kode_group}</td>
                <td> {item.kode_dept}</td>
                <td> {item.nama_barang}</td>
                <td> {item.tipe_barang}</td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{formatGram(item.berat, 3)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="7"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {" "}
                Total Barang Terkirim : {this.props.data.length}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat), 0)
                  .toFixed(3)}{" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanKirimBarangExel;
