import { getMasterToko } from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  ReanderSelect,
  Field,
  Tabel,
  Loading,
  getData,
  ToastNotification,
  setItem,
  getItem,
} from "../../../components/helpers/library";
import { getDataBaki, GetDataGroup } from "../TambahBarang/Module";
// import { getDataNoTimeOut } from "../../../config/axios";
// import { getDataBaki } from "../../Master/MasterBaki/ModuleMasterBaki";
// import { getMasterGroup } from "../../Master/MasterGroup/Module";

const maptostate = (state) => {
  return {
    databaki: state.datamaster.getDataBaki,
    datagroup: state.datamaster.GetDataGroup,
    dataToko: state.datamaster.getMasterToko,
  };
};

class HeadKirimBarang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      datajenis: [],
      active : "none",
      columns: [
        {
          dataField: "kode_barang",
          text: "Kode Barang ",
          sort: true,
          footerAttrs: {
            colSpan: "7",
          },
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Semua Barang : {getItem("kirimdatabarang").length || 0}{" "}
              </div>
            );
          },
        },
        {
          dataField: "kode_barcode",
          text: "Kode Barcode",
        },
        {
          dataField: "kode_group",
          text: "Kode Group",
        },
        {
          dataField: "kode_toko",
          text: "Kode Baki",
        },
        {
          dataField: "kode_dept",
          text: "Kode Jenis",
        },
        {
          dataField: "nama_barang",
          text: "Nama Barang",
        },
        {
          dataField: "tipe_barang",
          text: "Tipe Barang",
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (row) => {
            return (
              <div className="text-right">
                {" "}
                {row === null || undefined ? row : row.toFixed(3)}{" "}
              </div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {parseFloat(
                columnData.reduce((acc, item) => acc + item, 0)
              ).toFixed(3)}{" "}
            </div>
          ),
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {}

  componentDidMount() {
    setItem("kirimdatabarang", []);
    this.props.dispatch(getDataBaki());
    this.props.dispatch(GetDataGroup());
    this.props.dispatch(getMasterToko());
    this.props.change("kode_group","ALL")
    this.props.change("kode_jenis","ALL")
    this.props.change("kode_baki","ALL")
  }

  cariJenis(e) {
    getData("group/get/by-kode-group?kode_group=" + e).then((res) => {
      setItem("data_group", res.data);
    });
    getData("jenis/get/by-kode-kategori/" + e)
      .then((res) => {
        this.setState({
          datajenis: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          datajenis: [],
        });
        ToastNotification("info", err.response?.data.message);
      });
  }

  render() {
    let group = [
      {
        value: "ALL",
        name: "SEMUA",
      }
    ];
    this.props.datagroup.forEach((list) => {
      let data = {
        value: list.kode_group,
        name: list.kode_group,
      };
      group.push(data)
    })
    let jenis = [
      {
        value: "ALL",
        name: "SEMUA",
      }
    ];
    this.state.datajenis.forEach((list) => {
      let data = {
        value: list.kode_dept,
        name: list.kode_dept,
      };
      jenis.push(data)
    })
    let baki = [
      
    ];
    this.props.databaki.forEach((list) => {
      let data = {
        value: list.kode_baki,
        name: list.kode_baki,
      };
      baki.push(data)
    })
    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-lg-3">
            <Field
              label="Kode Baki"
              name="kode_baki"
              options={baki}
              placeholder={"Silahkan Pilih Kode Baki"}
              component={ReanderSelect}
            />
          </div>
          <div className="col-lg-3">
            <Field
              label="Tujuan"
              name="tujuan"
              options={this.props.dataToko.map((data) => {
                let hasil = {
                  value: data.kode_toko,
                  name: data.kode_toko,
                };
                return hasil;
              })}
              placeholder={"Silahkan Pilih Tujuan"}
              component={ReanderSelect}
            />
          </div>
          <div
            className="col-lg-2 mt-4"
          >
            <button
              disabled={this.props.isLoadingBarang}
              className="btn btn-primary btn-block"
              type="submit"
            >
              {this.props.isLoadingBarang ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Mencari
                </>
              ) : (
                "Cari Barang"
              )}
            </button>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="kode_barcode"
              btnText="Cari Data"
              // tambahData={true}
              exportCsv={false}
              data={this.props.databarang}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Barang Kosong"
            />
          </div>
          
        </div>

        {this.props.isLoadingBarang ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadKirimBarang = reduxForm({
  form: "HeadKirimBarang",
  enableReinitialize: true,
})(HeadKirimBarang);
export default connect(maptostate)(HeadKirimBarang);
