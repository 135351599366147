
import { PanelBody } from "../../../../components/panel/panel";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  postDataNoTImeOut2,
  ToastNotification,
  reset,
} from "../../../../components/helpers/library";
import FormAmbilBahanTukang from "./FormAmbilBahanTukang";

class AmbilBahanTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings : "",
    };
  }
  handleSubmit(data){
    this.setState({
      isLoading : true
    })
    let hasil = {
        kode_tukang: data.kode_tukang,
        jenis: data.jenis,
        kode_group: data.kategori,
        jml_bahan: parseInt(data.tambah_bahan),
        keterangan: data.keterangan
    }
    postDataNoTImeOut2('bahan/ambil-bahan',hasil).then((res)=>{
      this.setState({
        isLoading : false
      })
      ToastNotification('success','Ambil Bahan Tersimpan')
      this.props.dispatch(reset('FormAmbilBahanTukang'))
    }).catch((err)=>{
      this.setState({
        isLoading : false
      })
      ToastNotification('info',err.response?.data?.message || "Terjadi Kesalahan Saat Mengirim Data")
    })
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Ambil Bahan Tukang</li>
        </ol>
        <h1 className="page-header">Ambil Bahan Tukang </h1>
        <Panel>
          <PanelHeader>Ambil Bahan Tukang</PanelHeader>
         <PanelBody>
            <FormAmbilBahanTukang isLoading={this.state.isLoading} onSubmit={(data)=>this.handleSubmit(data) }/>
         </PanelBody>
        </Panel>
      </div>
    );
  }
}

AmbilBahanTukang = reduxForm({
  form: "AmbilBahanTukang",
  enableReinitialize: true,
})(AmbilBahanTukang);
export default connect(null)(AmbilBahanTukang);