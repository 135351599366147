import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { hideModal } from "../../../actions/datamaster_action";
import { HiidenFiled, ReanderField } from "../../../components/helpers/field";
import ValidasiKondisiBarang from "../../../Validasi/ValidasiKondisiBarang.jsx";
import Loading from "react-fullscreen-loading";

const maptostate = (state) => {
  if (state.datamaster.ShowModalJenisGroup !== null) {
    return {
      initialValues: {
        id: state.datamaster.ShowModalJenisGroup.id,
        jenis_group_lama:
          state.datamaster.ShowModalJenisGroup.jenis_group,
          jenis_group: state.datamaster.ShowModalJenisGroup.jenis_group,
      },
    };
  }
};
class FormModalJenisGroup extends Component {
  state = {
    aktif: false,
  };

  componentDidMount() {
    setTimeout(() => {
      document.getElementById("jenis_group").focus();
    }, 100);
  }
  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <input name="jenis_group_lama" type="hidden" />
        {
          this.props.isEdit ? <>
             <Field
              name="id"
              component={HiidenFiled}
              type="hidden"
              readOnly={this.props.isEdit}
            />
          </> : null
        }
        <Field
          tabIndex="1"
          id="jenis_group"
          name="jenis_group"
          component={ReanderField}
          type="text"
          label="Kode Jenis Group"
          onBlur={() => this.setState({ aktif: true })}
          placeholder="Masukan Kode Jenis Group"
        />
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="2"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormModalJenisGroup = reduxForm({
  form: "ModalJenisGroup",
  enableReinitialize: true,
  validate: ValidasiKondisiBarang,
})(FormModalJenisGroup);
export default connect(maptostate, null)(FormModalJenisGroup);
