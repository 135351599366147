import React from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../../components/panel/panel.jsx";
import {
  showModal,
  hideModal,
  getMasterToko,
  getDataJenisGroup,
  ShowModalJenisGroup,
} from "../../../actions/datamaster_action.jsx";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  NotifSucces,
  ToastNotification,
} from "../../../components/helpers/notification.jsx";
import { postData, putData } from "../../../config/axios.jsx";
import { reset } from "redux-form";
import {
  LoopingDeleteData,
  LoopingEditData,
  LoopingSendData,
} from "../../../components/helpers/function.jsx";
import Tabel from "../../../components/helpers/tabel.jsx";
import ModalGlobal from "../../../components/helpers/ModalGlobal.jsx";
import FormModalJenisGroup from "./FormModalJenisGroup.jsx";

const maptostate = (state) => {
  return {
    GetDataJenisGroup: state.datamaster.GetDataJenisGroup,
    hideModal: state.datamaster.modalDialog,
  };
};

const hapusGetDataJenisGroup = (params, dispatch) => {
  Swal.fire({
    html:
      "Apakah Anda Yakin Ingin " +
      "Menghapus " +
      "<h1><b>Kode Kondisi Barang " +
      params.jenis_group +
      "</b> ?</h1>",
    icon: "warning",
    position: "top-center",
    cancelButtonText: "Tidak",
    showCancelButton: true,
    confirmButtonText: "OK",
    showConfirmButton: true,
  })
    .then((result) => {
      if (result.isConfirmed) {
        putData("para-jenis-group/delete", { jenis_group: params.jenis_group })
          .then(() => {
            NotifSucces("Data Berhasil Di Hapus").then(() =>
              dispatch(getDataJenisGroup())
            );
          })
          .then(() =>
            dispatch(
              LoopingDeleteData("para-jenis-group/delete", {
                jenis_group: params.jenis_group,
              })
            )
          );
      }
    })
    .catch((err) => {
      ToastNotification("error", err.response.data);
    });
};
class MasterJenisGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isLoading: false,
      modalDialog: false,
      columns: [
        {
          dataField: "jenis_group",
          text: "Kode Jenis Group",
          sort: true,
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            let dataEdit = {
              id: row._id,
              jenis_group: row.jenis_group,
            };

            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => this.formEditKondisiBarang(dataEdit)}
                    className="btn btn-primary mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    onClick={() =>
                      hapusGetDataJenisGroup(row, this.props.dispatch)
                    }
                    className="btn btn-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDataJenisGroup());
    this.props.dispatch(getMasterToko());
  }

  handleSubmit(data) {
    if (data.jenis_group === undefined) {
      ToastNotification("warning", "Mohon Isi Jenis Group");
      return false;
    }
    this.setState({
      isLoading: true,
    });
    let data_post = {
      jenis_group: data.jenis_group.toUpperCase(),
    };
    let data_edit_cabang = {};
    if (this.state.isEdit) {
      data_edit_cabang = {
        jenis_group: data.jenis_group.toUpperCase(),
        jenis_group_old: data.jenis_group_lama.toUpperCase(),
      };
    }

    this.state.isEdit
      ? putData("para-jenis-group", data_edit_cabang)
          .then(() => {
            this.props.dispatch(hideModal());
          })
          .then(() => ToastNotification("success", "Data Berhasil Diedit"))
          .then(() => {
            this.props.dispatch(getDataJenisGroup());
          })
          .then(() => {
            this.setState({ isLoading: false });
          })
          .then(() => {
            this.props.dispatch(reset("ModalKondisiBarang"));
          })
          .then(() => {
            this.props.dispatch(
              LoopingEditData("para-jenis-group", data_edit_cabang)
            );
          })
          .catch((error) => {
            ToastNotification(
              "info",
              error.response === undefined
                ? "Terjadi Kesalahan Saat Megirim Data "
                : error.response.data
            );
            this.setState({ isLoading: false });
          })
      : postData("para-jenis-group", data_post)
          .then(() => {
            this.props.dispatch(hideModal());
          })
          .then(() => ToastNotification("success", "Data Berhasil Disimpan"))
          .then(() => {
            this.props.dispatch(getDataJenisGroup());
          })
          .then(() => {
            this.props.dispatch(reset("ModalKondisiBarang"));
          })
          .then(() => {
            this.setState({ isLoading: false });
          })
          .then(() => {
            this.props.dispatch(LoopingSendData("para-jenis-group", data_post));
          })
          .catch((error) => {
            ToastNotification(
              "info",
              error.response === undefined
                ? "Terjadi Kesalahan Saat Megirim Data "
                : error.response.data
            );
            this.setState({ isLoading: false });
          });
  }

  formEditKondisiBarang(dataEdit) {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowModalJenisGroup(dataEdit));
    this.setState({
      isEdit: true,
    });
  }

  ShowModalJenisGroup() {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowModalJenisGroup(false));
    this.setState({
      isEdit: false,
    });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Master Jenis Group</li>
        </ol>
        <h1 className="page-header">Master Jenis Group </h1>
        <Panel>
          <PanelHeader>Master Jenis Group</PanelHeader>
          <br />

          {/* Master Jenis Group */}
          <PanelBody>
            <Tabel
              keyField="jenis_group"
              tambahData={true}
              handleClick={() => this.ShowModalJenisGroup()}
              exportCsv={true}
              data={this.props.GetDataJenisGroup}
              columns={this.state.columns}
              empty={this.props.GetDataJenisGroup}
              textEmpty="Data Baki Kosong"
            />
          </PanelBody>
          <br />
          {/* End Master Sales */}
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit
                ? "Edit Data Jenis Group"
                : "Tambah Data Jenis Group"
            }
            content={
              <FormModalJenisGroup
                isEdit={this.state.isEdit}
                isLoading={this.state.isLoading}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            }
          />

          {/* End Tambah Master Jenis Group  */}
        </Panel>
      </div>
    );
  }
}

export default connect(maptostate, null)(MasterJenisGroup);
