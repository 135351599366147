import {
  GetDataGroup,
  getDataJenis,
  getDataKondisiBarang,
  getMasterToko,
} from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderSelect,
  InputDate,
  getTglSystem,
  Loading,
  Tabel,
  getItem,
  setItem,
} from "../../../components/helpers/library";
import ValidasiSortirPembelian from "../../../Validasi/Pembelian/ValidasiSortirPembelian";

class HeadSortir extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
    };
    this.setStartDate = this.setStartDate.bind(this);

    // this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(GetDataGroup());
    this.props.dispatch(getDataKondisiBarang());
    this.props.dispatch(getDataJenis());
    this.props.dispatch(getMasterToko());
    setItem("datasortir_barang", []);
    setItem("filter_sortir", []);
    this.props.change("kode_group", "ALL")
    this.props.change("kondisi", "ALL")
    this.props.change("kode_dept", "ALL")
    this.props.change("tanggal", getTglSystem())
  }

  componentWillUnmount() {
    setItem("datasortir_barang", []);
    setItem("filter_sortir", []);
  }
  setStartDate(date) {
    this.setState({
      tanggal: new Date(date),
    });
  }
  render() {
    let DataToko =  [
    ];
    this.props.DataToko.forEach(list => {
      let data = {
        value: list.kode_toko,
        name: list.kode_toko,
      };
      DataToko.push(data)
    });
    let GetDataGroup =  [
      {
        value : "ALL",
        name : "SEMUA"
      }
    ];
    this.props.GetDataGroup.forEach(list => {
      let data = {
        value: list.kode_group,
        name: list.kode_group,
      };
      GetDataGroup.push(data)
    });
    let KondisiBeli =  [
      {
        value : "ALL",
        name : "SEMUA"
      }
    ];
    this.props.KondisiBeli.forEach(list => {
      let data = {
        value: list.kondisi_barang,
        name: list.kondisi_barang,
      };
      KondisiBeli.push(data)
    });
    let DataJenis =  [
      {
        value : "ALL",
        name : "SEMUA"
      }
    ];
    this.props.DataJenis.forEach(list => {
      let data = {
        value: list.kode_dept,
        name: list.kode_dept,
      };
      DataJenis.push(data)
    });

    const selectRowPembelian = {
      mode: "checkbox",
      clickToSelect: true,
      selectColumnPosition: "left",
      onSelect: (row, isSelect, rowIndex, e) => {
        // console.log(isSelect);
        this.setState({
          berhasil: true,
        });
        if (isSelect === true) {
          let hasil = {
            no_faktur_beli: row.no_faktur_beli,
            tgl_system: row.tgl_system,
            kondisi: row.kondisi,
            nama_barang: row.nama_barang,
            kode_dept: row.kode_dept,
            kode_group: row.kode_group,
            berat: row.berat,
            harga: row.harga,
          };
          let cek = JSON.parse(localStorage.getItem("datasortir_barang")) || [];
          let hasil_cek = cek.findIndex(
            (hasil) => hasil.no_faktur_beli === row.no_faktur_beli
          );
          if (hasil_cek === -1) {
            cek.push(hasil);
            localStorage.setItem("datasortir_barang", JSON.stringify(cek));
          }
        } else {
          if (localStorage.getItem("datasortir_barang") === null) {
          } else {
            let data = JSON.parse(localStorage.getItem("datasortir_barang"));
            data.splice(row.no_faktur_beli, 1);
            localStorage.setItem("datasortir_barang", JSON.stringify(data));
          }
        }
      },
      onSelectAll: (isSelect, data, e) => {
        this.setState({
          berhasil: true,
        });
        data.forEach((list, index) => {
          if (isSelect === true) {
            let hasil = {
              no_faktur_beli: list.no_faktur_beli,
              tgl_system: list.tgl_system,
              kondisi: list.kondisi,
              nama_barang: list.nama_barang,
              kode_dept: list.kode_dept,
              kode_group: list.kode_group,
              berat: list.berat,
              harga: list.harga,
            };
            let cek =
              JSON.parse(localStorage.getItem("datasortir_barang")) || [];
            let hasil_cek = cek.findIndex(
              (hasil) => hasil.no_faktur_beli === list.no_faktur_beli
            );
            if (hasil_cek === -1) {
              cek.push(hasil);
              localStorage.setItem("datasortir_barang", JSON.stringify(cek));
            }
          } else {
            if (localStorage.getItem("datasortir_barang") === null) {
            } else {
              let data = JSON.parse(localStorage.getItem("datasortir_barang"));
              data.splice(list.no_faktur_beli, 1);
              localStorage.setItem("datasortir_barang", JSON.stringify(data));
            }
          }
        });
      },
    };
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-4">
            <Field
              name="kode_toko"
              label="Toko"
              placeholder="Pilih Toko"
              options={DataToko}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <Field
              name="tanggal"
              component={InputDate}
              label="Tanggal"
              type="text"
              selected={this.state.tanggal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal"
            />
          </div>
          <div className="col-4">
            <Field
              name="kode_group"
              label="Kategori"
              placeholder="Pilih Kategori"
              options={GetDataGroup}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <Field
              name="kondisi"
              label="Kondisi"
              placeholder="Pilih Kondisi"
              options={KondisiBeli}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <Field
              name="kode_dept"
              label="Jenis"
              placeholder="Pilih Jenis"
              options={DataJenis}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <label>&nbsp;</label>
            <button type="submit" className="btn btn-primary btn-block">
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Mencari
                </>
              ) : (
                "Cari"
              )}
            </button>
          </div>
          <div className="col-12">
            <Tabel
              selectRow={selectRowPembelian}
              keyField="no_faktur_beli"
              data={this.props.data}
              columns={this.props.columns}
              empty={true}
              textEmpty="Data Barang Kosong"
            />
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
        <Tabel
          keyField="no_faktur_beli"
          data={getItem("datasortir_barang")}
          columns={this.props.columns2}
          empty={true}
          textEmpty="Data Barang Kosong"
        />
      </form>
    );
  }
}

HeadSortir = reduxForm({
  form: "HeadSortir",
  enableReinitialize: true,
  validate: ValidasiSortirPembelian,
})(HeadSortir);
// export default connect()(HeadSortir);
export default connect((state) => {
  return {
    GetDataGroup: state.datamaster.GetDataGroup,
    KondisiBeli: state.datamaster.getDataKondisiBarang,
    DataJenis: state.datamaster.getDataJenis,
    DataToko: state.datamaster.getMasterToko,
    initialValues: {
      tanggal: getTglSystem(),
    },
  };
})(HeadSortir);
