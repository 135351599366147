import {
  GetDataGroup,
  getDataTukang,
} from "../../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Loading,
  Tabel,
  Field,
  InputDate,
  getTglSystem,
  ReanderSelect,
} from "../../../../components/helpers/library";
import LaporanBahanTukangExel from "./exel/LaporanBahanTukangExel";
import LaporanBahanTukangRekapExel from "./exel/LaporanBahanTukangRekapExel";
import LaporanBahanTukang from "./pdf/LaporanBahanTukang";
import LaporanBahanTukangRekap from "./pdf/LaporanBahanTukangRekap";

class HeadLaporanBahanTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columnsDetail: [
        {
          dataField: "kode_tukang",
          text: "Tukang",
          footer: "",
          footerAttrs: {
            colSpan: "3",
          },
        },
        {
          dataField: "kode_trx",
          text: "Kode",
        },
        {
          dataField: "detail_kategori",
          text: "Kategori",
        },
        {
          dataField: "keterangan",
          text: "Keterangan",
          footer: "Grand Total : ",
        },
        {
          dataField: "ongkos",
          text: "Ongkos",
          footer: (row) => {
            let total_ongkos = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_ongkos += 0;
              } else {
                total_ongkos += element;
              }
            });
            return total_ongkos;
          },
        },
        {
          dataField: "susut",
          text: "Susut",
          footer: (row) => {
            let total_susut = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_susut += 0;
              } else {
                total_susut += element;
              }
            });
            return total_susut;
          },
        },
        {
          dataField: "patri",
          text: "Patri",
          footer: (row) => {
            let total_patri = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_patri += 0;
              } else {
                total_patri += element;
              }
            });
            return total_patri;
          },
        },
        {
          dataField: "bahan",
          text: "Bahan",
          footer: (row) => {
            let total_bahan = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_bahan += 0;
              } else {
                total_bahan += element;
              }
            });
            return total_bahan;
          },
        },
        {
          dataField: "pakai",
          text: "Pakai",
          footer: (row) => {
            let total_pakai = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_pakai += 0;
              } else {
                total_pakai += element;
              }
            });
            return total_pakai;
          },
        },
        {
          dataField: "patri_amb",
          text: "Patri Amb",
          footer: (row) => {
            let total_patri_amb = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_patri_amb += 0;
              } else {
                total_patri_amb += element;
              }
            });
            return total_patri_amb;
          },
        },
        {
          dataField: "bahan_amb",
          text: "Bahan Amb",
          footer: (row) => {
            let total_bahan_amb = 0;
            row.forEach((element) => {
              if (element === "-") {
                total_bahan_amb += 0;
              } else {
                total_bahan_amb += element;
              }
            });
            return total_bahan_amb;
          },
        },
        {
          dataField: "sisa_bhn",
          text: "Sisa Bahan",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
          footer: (row) => {
            return <div className="text-right">{row.reduce((a,b)=>a + parseInt(b),0).toFixed(3)}</div>
          },
        },
      ],
      columnsRekap: [
        {
          dataField: "kode_tukang",
          text: "Tukang",
          footer: "Grand Total : ",
          footerAttrs: {
            colSpan: "2",
          },
        },
        {
          dataField: "kode_kategori",
          text: "Kategori",
        },
        {
          dataField: "total_bahan",
          text: "Total Bahan",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
          footer: (row) => {
            return <div className="text-right">{row.reduce((a,b)=>a + parseInt(b),0).toFixed(3)}</div>
          },
        },
        {
          dataField: "pakai_bahan",
          text: "Pakai Bahan",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
          footer: (row) => {
            return <div className="text-right">{row.reduce((a,b)=>a + parseInt(b),0).toFixed(3)}</div>
          },
        },
        {
          dataField: "patri",
          text: "Patri",
          footer: (row) => {
            let total_patri = 0;
            row.forEach((element) => {
              total_patri += element;
            });
            return total_patri;
          },
        },
        {
          dataField: "sisa_bhn",
          text: "Sisa Bahan",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
          footer: (row) => {
            return <div className="text-right">{row.reduce((a,b)=>a + parseInt(b),0).toFixed(3)}</div>
          },
        },
      ],
    };
    // this.print = this.print.bind(this);
  }

  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  // setTypeLaporan(type) {
  //   this.setState({
  //     typelaporan: type,
  //     exportlap: false,
  //     laporanbahan: [],
  //   });
  // }
  setExportData() {
    this.setState({
      exportlap: true,
    });
  }
  exportPdf() {
    if (this.props.typelaporan === "DETAIL") {
      LaporanBahanTukang(this.props.LaporanBahanTukang);
    } else {
      LaporanBahanTukangRekap(this.props.LaporanBahanTukang);
    }
  }
  componentDidMount() {
    this.props.dispatch(getDataTukang());
    this.props.dispatch(GetDataGroup());
    this.props.change("kategori", "ALL");
    this.props.change("type_laporan", "DETAIL");
    // this.props.change("gudang", "STOCK");
    this.props.change("kode_tukang", "ALL");
    this.setState({
      laporanbahan: this.props.dataexel,
    });
    console.log(this.props.laporanbahan);
  }
  render() {
    let datatukang = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    let datagroup = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    // let datagudang = [
    //   {
    //     value: "STOCK",
    //     name: "STOCK",
    //   },
    // ];
    let type_laporan = [
      {
        name: "DETAIL",
        value: "DETAIL",
      },
      {
        name: "REKAP",
        value: "REKAP",
      },
    ];
    this.props.DataGroup.forEach((list) => {
      let row = {
        name: list.kode_group,
        value: list.kode_group,
      };
      datagroup.push(row);
    });

    this.props.DataTukang.forEach((element) => {
      let row = {
        name: element.nama_tukang,
        value: element.kode_tukang,
      };
      datatukang.push(row);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
        <div className="col-lg-4">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>
          <div className="col-lg-4">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-4">
            <Field
              id="type_laporan"
              name="type_laporan"
              component={ReanderSelect}
              type="text"
              options={type_laporan}
              label="Type"
              placeholder="Silahkan Pilih Type"
              onChange={this.props.typeLap}
            />
          </div>
          <div className="col-lg-4">
            <Field
              id="kode_tukang"
              name="kode_tukang"
              component={ReanderSelect}
              type="text"
              options={datatukang}
              label="Kode Tukang"
              placeholder="Silahkan Pilih Kode Tukang"
            />
          </div>
          <div className="col-lg-4">
            <Field
              id="kategori"
              name="kategori"
              component={ReanderSelect}
              type="text"
              options={datagroup}
              label="Kategori"
              placeholder="Silahkan Pilih Kategori"
            />
          </div>
         
         
          {/* <div
            className={
              this.props.typelaporan === "REKAP" ? "col-lg-4" : "d-none"
            }
          >
            <Field
              id="gudang"
              name="gudang"
              component={ReanderSelect}
              type="text"
              options={datagudang}
              label="Gudang"
              placeholder="Silahkan Pilih Gudang"
            />
          </div> */}
          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
                onClick={() => this.setExportData()}
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_pesanan"
              data={this.props.export ? this.props.LaporanBahanTukang : []}
              columns={
                this.props.typelaporan === "DETAIL"
                  ? this.state.columnsDetail
                  : this.state.columnsRekap
              }
              empty={true}
              textEmpty="Data Laporan Bahan Tukang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  //   onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                  onClick={() => this.exportPdf()}
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                {this.props.typelaporan === "DETAIL" ? (
                  <LaporanBahanTukangExel
                    dataexel={this.props.LaporanBahanTukang || []}
                  />
                ) : (
                  <LaporanBahanTukangRekapExel
                    dataexel={this.props.LaporanBahanTukang || []}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanBahanTukang = reduxForm({
  form: "HeadLaporanBahanTukang",
  enableReinitialize: true,
})(HeadLaporanBahanTukang);
export default connect((state) => {
  return {
    DataGroup: state.datamaster.GetDataGroup,
    DataTukang: state.datamaster.getDataTukang,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadLaporanBahanTukang);
