import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPdfKirimbarangbos = (
  data = "",
) => {
  // initialize jsPDF
  console.log(data);
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let finalY = 30;
  doc.setFontSize(15);
  doc.text("Laporan Kirim Barang Bos", 14, 15);
  doc.setFontSize(10);
  doc.setProperties({
    title: "Laporan Kirim Barang Bos",
  });
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
          " s/d " +
          JSON.parse(localStoragedecryp("tgl_laporan")).tgl_akhir
    }`,
    14,
    25
  );
  let tableColumn = [
    [
      {
        content: `NO KIRIM`,
      },
      {
        content: `KODE LABELING`,
      },
      {
        content: `SALES PABRIK`,
      },
      {
        content: `KADAR`,
      },
      {
        content: `KODE GROUP`,
      },
      {
        content: `KODE DEPT`,
      },
      {
        content: `ASAL BARNAG`,
      },
      {
        content: `QTY`,
      },
      {
        content: `BERAT`,
      },
    ],
    ];
  let totalqty = 0;
  let totalberat = 0;
  data.forEach((item, no) => {

      let rows = [
        item.no_kirim_bos,
        item.kode_labeling,
        item.sales_pabrik,
        // item.kadar,
        {
          content: item.kadar,
          styles: {
            halign: 'right',
          },
        },
        item.kode_group,
        item.kode_dept,
        item.asal_barang,
        {
          content: item.qty,
          styles: {
            halign: 'right',
          },
        },
        {
          content: item.berat.toFixed(3),
          styles: {
            halign: 'right',
          },
        },
      ];

      totalqty = totalqty + item.qty;
      totalberat = totalberat + item.berat;
      tableRows.push(rows);
  });

  let footer = [
    {
      content: `Total Barang : ${data.length}`,
      colSpan: 7,
      styles: {
        halign: 'left',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${totalqty}`,
      styles: {
        halign: 'right',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${totalberat.toFixed(3)}`,
      styles: {
        halign: 'right',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    // {
    //   content: `Total Barang : ${total_barang} Pcs\nTotal Berat : ${grantotalberat.toFixed(
    //     3
    //   )}\nTotal Berat Atribut : ${grantotalberatatr.toFixed(3)}`,
    //   colSpan: 10,
    //   styles: {
    //     fillColor: "#E8E5E5",
    //     textColor: "#000",
    //   },
    // },
  ];

  tableRows.push(footer);
  let printed = [
    {
      colSpan:9,
      content: `Printed By ${JSON.parse(localStoragedecryp("userdata")).user_id} / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ]
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 20 },
    // bodyStyles: { lineWidth: 0.02, lineColor: "#000" },
    // footStyles: { lineWidth: 0.02, lineColor: "#000" },
    headStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
  });
  finalY = doc.autoTableEndPosY() + 10;
  
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Laporan Kirim Barang Bos</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPdfKirimbarangbos;
