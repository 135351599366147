import moment from "moment";
import React from "react";
import Avatar from "react-avatar";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Swal from "sweetalert2";
import { postData, putData } from "../../../config/axios";
import { formatDate, getUserdata } from "../../helpers/function";
import { Loading, postDataNoTImeOut, showModal } from "../../helpers/library";
import { ToastNotification } from "../../helpers/notification";

const logout = () => {
  Swal.fire({
    title: "Konfirmasi Keluar !!",
    text: "Apakah anda ingin keluar ?",
    icon: "info",
    position: "top-center",
    cancelButtonText: "Tidak",
    showCancelButton: true,
    confirmButtonText: "OK",
    showConfirmButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      // window.location.reload();
      // this.props.history.push("/")
      // window.location.href = "/nagagold_online.com/";
      // localStorage.clear();
      // localStorage.setItem("islogin", "false");
      postData("users/logout", { user_id: getUserdata().user_id })
        .then((res) => {
          // console.log(res.data);
          // ToastNotification("success", "Berhasil Menutup Toko");
          // setTimeout(() => {
          // window.location.reload();
          window.location.href = "/";
          localStorage.clear();
          localStorage.setItem("islogin", "false");
          // }, 1000);
        })
        .catch((err) => {
          ToastNotification(
            "info",
            err.response === undefined ? "Gagal Logout" : err.response.data
          );
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        });
    }
  });
};
class DropdownProfile extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      modalDialog: false,
      isLoading: false,
      isLoadingTutupToko: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState(({
      isLoading : true
    }))
    let hasil = {
      password : this.state.password_lama,
      new_password : this.state.password_baru,
      // retype_new_password : this.state.password_baru,
    }

    putData("users/change-password", hasil).then((res) => {
      this.toggleModal("modalDialog")
      this.setState(({
        isLoading : false
      }))
      ToastNotification('success', "Password Berhasil Diubah")
    }).catch((err) => {
      ToastNotification('info', err.response === undefined ? "Terjadi Kesalahan Saat Request Data" : err.response.data)
    })
    
  }
  onChance = (nama) => (e) => {
    this.setState({
      [nama]: e.target.value,
    });
  };
  toggleModal(name) {
    switch (name) {
      case "modalDialog":
        this.setState({ modalDialog: !this.state.modalDialog });
        break;
      default:
        break;
    }
  }
  showModalTutupToko() {
    var datatoday = new Date();
    var datatodays = datatoday.setDate(
      new Date(new Date(getUserdata().tgl_system)).getDate() + 1
    );
    var todate = new Date(datatodays);
    Swal.fire({
      html:
        "<h1><b>Apakah Anda Yakin Ingin  <br/> " +
        "Menutup Toko" +
        "</b> ?</h1><br>" +
        "Dari Tanggal " +
        getUserdata().tgl_system +
        " Ke " +
        formatDate(moment(new Date(todate)).format("YYYY-MM-DD")),
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          isLoadingTutupToko : true
        })
        postDataNoTImeOut("system/close", { PROSES: "close" })
          .then((res) => {
            ToastNotification("success", "Berhasil Menutup Toko");
           
            setTimeout(() => {
              // window.location.reload();
              window.location.href = "/";
              localStorage.clear();
              localStorage.setItem("islogin", "false");
              this.setState({
                isLoadingTutupToko : false
              })
            }, 1000);
          })
          .catch((err) => {
            this.setState({
              isLoadingTutupToko : false
            })
            // console.log(err.response == undefined);
            err.response === undefined
              ? ToastNotification(
                  "info",
                  err.response === undefined
                    ? "Terjadi Kesalahan Saat Request Data"
                    : err.response.data
                )
              : err.response.data === "Invalid token."
              ? this.handleInvalidToken()
              : ToastNotification("info", err.response.data);
            // setTimeout(() => {
            //   window.location.reload();
            //   localStorage.clear();
            //   localStorage.setItem("islogin", "false");
            // }, 1000);
          });
      } else {
        this.setState({
          isLoadingTutupToko : false
        })
      }
    });
  }

  handleInvalidToken() {
    ToastNotification("info", "Toko Sudah Ditutup Oleh Akun Lain");

    setTimeout(() => {
      window.location.href = "/";
      localStorage.clear();
      localStorage.setItem("islogin", "false");
    }, 1000);
  }
  gantiPassword() {
    this.props.dispatch(showModal());
  }
  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="dropdown navbar-user"
        tag="li"
      >
        <DropdownToggle tag="a">
          <Avatar
            name={getUserdata().user_id}
            size="30"
            color="#00743C"
            round="20px"
          />
          <span
            className="d-none d-md-inline"
            style={{ cursor: "pointer" }}
          ></span>{" "}
          <b className="caret"></b>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          {/* <DropdownItem>Edit Profile</DropdownItem> */}
          {/* <DropdownItem>Setting</DropdownItem> */}
          <DropdownItem onClick={() => this.showModalTutupToko()}>
            Tutup Toko
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={() => this.toggleModal("modalDialog")}>
          Ganti Password
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem  onClick={() => logout()}>
              {" "}
              Logout{" "}
          </DropdownItem>
        </DropdownMenu>
        <Modal
          isOpen={this.state.modalDialog}
          toggle={() => this.toggleModal("modalDialog")}
        >
          <ModalHeader toggle={() => this.toggleModal("modalDialog")}>
            Ganti Password
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label> Password Lama </label>
                <input
                  id="password_lama"
                  type="password"
                  name="password_lama"
                  value={this.state.password_lama}
                  onChange={this.onChance("password_lama")}
                  className="form-control"
                  placeholder="Masukan Password Lama"
                />
              </div>
              <div className="form-group">
                <label> Password Baru </label>
                <input
                  type="password"
                  name="password_baru"
                  value={this.state.password_baru}
                  onChange={this.onChance("password_baru")}
                  className="form-control"
                  placeholder="Masukan Password Baru"
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-white"
                  onClick={() => this.toggleModal("modalDialog")}
                >
                  Batal
                </button>
                &nbsp;
                <button disabled={this.props.isLoading} className="btn btn-primary" type="submit">
                  {this.props.isLoading ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                      Menyimpan
                    </>
                  ) : (
                    "Simpan Data"
                  )}
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        {this.state.isLoadingTutupToko ? (
        <Loading 
        loading
        background="rgba(0, 0, 0, 0.35)"
         loaderColor="rgba(94, 147, 117, 1)" />
      ) : null}
      </Dropdown>
    );
  }
}

export default DropdownProfile;
