import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  getDataNoTimeOut2,
  formatDate,
  moment,
  ToastNotification,
  localStorageencryp,
} from "../../../../components/helpers/library";
import FilterLaporanSo from "./FilterLaporanSo";

class LaporanSoPesanan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      LaporanStockOpname : [],
      pesanan : [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      kode_group: data.kategori,
      kode_tukang: data.tukang,
    };
    this.setState({
      isLoading: true,
    });

    getDataNoTimeOut2(
      `stock-opname/reports?kode_group=${data_post.kode_group}&kode_tukang=${data_post.kode_tukang}&tgl_from=${data_post.tgl_awal}&tgl_to=${data_post.tgl_akhir}`
    ).then((res) => {
      console.log(res.data);
      localStorageencryp("tgl_laporan", JSON.stringify(data_post));

      this.setState({
        isLoading: false,
      });
      if(res.data.length === 0){
        ToastNotification('info','Data Laporan So Kosong')
        return false
      }

      this.setState({
        pesanan : [],
        LaporanStockOpname: [],
      });

      res.data.forEach(element => {
        this.setState({
          pesanan : element.pesanan,
        });
      });

      this.setState({
        export : true,
        LaporanStockOpname: res.data
      });
    }).catch((err)=>{
      ToastNotification('info',err.response?.data.message || 'Data Laporan So Kosong')
      this.setState({
        isLoading: false,
      });
    })
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Pesanan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Pesanan So</li>
        </ol>
        <h1 className="page-header">Laporan Pesanan So </h1>
        <Panel>
          <PanelHeader>Laporan Pesanan So</PanelHeader>
          <br />
          {/* Laporan Pesanan So */}
          <PanelBody>
            <FilterLaporanSo export={this.state.export} pesanan={this.state.pesanan} LaporanStockOpname={this.state.LaporanStockOpname} isLoading={this.state.isLoading} onSubmit={(data) => this.handleSubmit(data)} />
          </PanelBody>

          <br />
        </Panel>
      </div>
    );
  }
}

LaporanSoPesanan = reduxForm({
  form: "LaporanSoPesanan",
  enableReinitialize: true,
})(LaporanSoPesanan);
export default connect()(LaporanSoPesanan);
