import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const BuktirSortirSepuhan = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;


  doc.setFontSize(15);
  doc.text("BUKTI SORTIR SEPUHAN", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0,48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48,90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "BUKTI SORTIR SEPUHAN",
  });
  doc.text(
    `TANGGAL : ${getToday()}`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `NO SORTIR`,
      },
      {
        content: `NAMA BARANG`,
      },
      {
        content: `KONDISI`,
      },
      {
        content: `BERAT`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `BAKI`,
      },
    ],
    ];
    
  let total = 0;
  data.buktiPrint.detail_sortir.forEach((row, index) => {
      total = total + row.berat;
      let rows = [
        data.buktiPrint.no_sortir,
        row.nama_barang,
        row.kondisi,
        {
          content: row.berat.toFixed(3),
          styles: {
            halign: 'right',
          },
        },
        row.kode_lokasi_toko,
      ];
      tableRows.push(rows)
    })
  
  let footer = [
    {
      content: `Total Transaksi:` + data.buktiPrint.detail_sortir.length,
      colSpan: 3,
      styles: {
        halign: 'center',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: '',
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
  ]
  tableRows.push(footer)

  let printed = [
    {
      content: `Printed By ${JSON.parse(localStoragedecryp("userdata")).user_id} / ${getToday()}`,
      colSpan: 7,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ]
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

// if(data.length)
//   doc.save(`Cetak Faktur Penjualan.pdf`);
    
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

doc.save(`BUKTI SORTIR SEPUHAN.pdf`);
  
};

export default BuktirSortirSepuhan;
