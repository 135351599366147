import Swal from "sweetalert2";
import { getMasterPabrik, isEdit, isLoading, showMasterPabrik, showModal } from "../../../actions/datamaster_action";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ModalGlobal,
  Tabel,
  reset,
  hideModal,
  ToastNotification,
  ErrorHandling,
  putData,
  postData,
  deleteData,
} from "../../../components/helpers/library";
import ModalPabrik from "./ModalPabrik";

const maptostate = (state) => {
  return {
    isEdit: state.datamaster.isEdit,
    isLoading: state.datamaster.isLoading,
    DataPabrik: state.datamaster.getMasterPabrik,
  };
};
class MasterPabrik extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
      columns: [
        {
          dataField: "nama_pabrik",
          text: "Pabrik",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            let data = {
              _id: row._id,
              pabrik: row.nama_pabrik,
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.editDataPabrik(data)}
                    className="btn btn-warning mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      this.hapusDataPabrik(row.nama_pabrik, row._id)
                    }
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
  }
  hapusDataPabrik(params, id) {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin " +
        "Menghapus " +
        "<h1><b>Kode Pabrik " +
        params +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData("masterpabrik/delete/by-nama-pabrik/" + id).then(() => {
          ToastNotification("success", "Data Berhasil Di Hapus").then(() =>
            this.props.dispatch(getMasterPabrik())
          );
        });
      }
    });
    }
    componentDidMount() {
        this.props.dispatch(getMasterPabrik())
    }
  batal() {
    this.props.dispatch(reset("ModalPabrik"));
    this.props.dispatch(hideModal());
  }
  showModalPabrik() {
    this.props.dispatch(isEdit(false));
    this.props.dispatch(showModal());
    this.props.dispatch(showMasterPabrik(false));
  }

  editDataPabrik(data) {
    this.props.dispatch(isEdit(true));
    this.props.dispatch(showModal());
    this.props.dispatch(showMasterPabrik(data));
  }
  simpanPabrik(data, props) {
    props.dispatch(isLoading(true));

    let data_post = {
      //   id: uuidv4(),
      nama_pabrik: data.pabrik,
    };

    setTimeout(() => {
      this.props.isEdit
        ? putData("masterpabrik/edit/by-nama-pabrik/" + data._id, data_post)
            .then(() => {
              this.props.dispatch(hideModal());
            })
            .then(() => {
              ToastNotification("success", "Data Berhasil Diedit");
            })
            .then(() => {
              this.props.dispatch(getMasterPabrik());
            })
            .then(() => {
              // this.setState({ isLoading: false });
              this.props.dispatch(isLoading(false));
            })
            .then(() => {
              this.props.dispatch(reset("ModalPabrik"));
            })
            .catch((error) => {
              ToastNotification("info", "Terjadi Kesalahan Saat Mengirim Data");
              this.props.dispatch(isLoading(false));
            })
        : postData("masterpabrik/add", data_post)
            .then(() => {
              this.props.dispatch(hideModal());
            })
            .then(() => {
              ToastNotification("success", "Data Berhasil Disimpan");
            })
            .then(() => {
              this.props.dispatch(getMasterPabrik());
            })
            .then(() => {
              this.props.dispatch(reset("ModalPabrik"));
            })
            .then(() => {
              this.props.dispatch(isLoading(false));
            })
            .catch((error) =>
              ErrorHandling(
                error,
                "masterpabrik/reactive/by-nama-pabrik/",
                data_post,
                props.dispatch,
                getMasterPabrik(),
                hideModal(),
                "ModalPabrik"
              ).then(() => {
                this.props.dispatch(isLoading(false));
              })
            );
    }, 300);
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Master Pabrik</li>
        </ol>
        <h1 className="page-header">Master Pabrik </h1>
        <Panel>
          <PanelHeader>Master Pabrik</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <Tabel
              tambahData={true}
              handleClick={() => this.showModalPabrik()}
              featur={true}
              keyField="_id"
              data={this.props.DataPabrik}
              columns={this.state.columns}
              empty={[]}
              textEmpty="Data Pabrik Kosong"
            />
            <br />
            {/* End Tambah Blank Page  */}
            <ModalGlobal
              size="P"
              title={
                this.props.isEdit ? "Edit Data Pabrik" : "Tambah Data Pabrik"
              }
              content={
                <ModalPabrik
                  onSubmit={(data) => this.simpanPabrik(data, this.props)}
                  batal={() => this.batal()}
                />
              }
            />
          </PanelBody>
          <br />
        </Panel>
      </div>
    );
  }
}

MasterPabrik = reduxForm({
  form: "MasterPabrik",
  enableReinitialize: true,
})(MasterPabrik);
export default connect(maptostate)(MasterPabrik);
