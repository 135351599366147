import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import {
  formatGram,
  getTglSystem,
} from "../../../../components/helpers/function";
import { ToastNotification,Tabel,Loading } from "../../../../components/helpers/library"
import ValidasiLaporanKeuangan from "../../../../Validasi/LaporanKeuangan/ValidasiLaporanKeuangan";
import { getDataJenis } from "../../../../actions/datamaster_action";
import ExportLaporanSortirGlobal from "./pdf/ExportLaporanSortirGlobal";
import ExportLaporanSortirDetail from "./pdf/ExportLaporanSortirDetail";
import { getDataNoTimeOut2 } from './../../../../config/axios';

class HeadLaporanSortirPembelian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      type: "GLOBAL",
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  componentDidMount() {
    this.props.change("type", "GLOBAL");
    this.props.change("kode_dept", "ALL");
    this.props.dispatch(getDataJenis());
  }
  exportPdfGlobal() {
    ExportLaporanSortirGlobal(this.props.data);
  }
  exportPdfDetail() {
    ExportLaporanSortirDetail(this.props.data);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  reprint(row){
    getDataNoTimeOut2("sortir-pembelian/reprint?no_sortir="+row.no_sortir).then((res)=>{
      this.print(res.data)
    }).catch((err)=>{
      console.log(err)
      ToastNotification("info","Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
    })
  }
print(data){
let datasepuh = [];
for (let index = 0; index < 2; index++) {
let nama = "";
if(index===0){
nama= "INTERNAL";
}else{
nama= "LABEL";
}
let feedback = '';
feedback += `========================================\n`
feedback += `      SORTIR PEMBELIAN ${data.kode_gudang} ${nama}\n`
feedback += `========================================
No Sortir        : ${data._id.no_sortir}
Kode Toko        : ${data.kode_toko}
Tgl Sortir       : ${data.tgl_sortir}
kondisi          : ${data.kondisi}
Kategori         : ${data.kode_group}
Qty Asal         : ${data.qty_asal===null ? "-" : data.qty_asal}
Berat Asal       : ${data.berat_asal===null? "-" : data.berat_asal}
Total Qty        : ${data.total_qty === null ? "-" : data.total_qty}
Total Berat      : ${data.total_berat}
Kode Baki        : ${data.kode_baki}
Jenis            : ${data.kode_dept}\n`
feedback += `========================================\n`
feedback += `              TERIMA KASIH              \n`
feedback += `========================================`
feedback += `






VA\n`;
datasepuh.push(feedback);
}
document.getElementById("myInput").value = datasepuh.join('');
const element = document.createElement("a");
const file = new Blob([document.getElementById("myInput").value], {
type: "text/plain;charset=utf-8",
});
element.href = URL.createObjectURL(file);
element.download = "autoprint_pembelian.txt";
document.body.appendChild(element);
element.click();
}

  render() {
    let datasortir = [];

    if (this.props.type === "GLOBAL") {
      this.props.data.forEach((row) => {
        let rows = {
          no_sortir: row._id.no_sortir,
          kondisi: row._id.kondisi,
          kode_baki: row.kode_baki,
          total_berat: row.total_berat,
          total_qty: row.total_qty,
        };
        datasortir.push(rows);
      });
    } else {
      this.props.data.forEach((list) => {
        let row = {
          no_sortir: list.no_sortir,
          kondisi: list.kondisi,
          berat: list.berat,
          tujuan: list.kode_baki,
        };
        datasortir.push(row);
      });

    }

    let DataJenis = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.datajenis.forEach((list) => {
      let data = {
        value: list.kode_dept,
        name: list.kode_dept,
      };
      DataJenis.push(data);
    });
    const columnsGlobal = [
      {
        dataField: "no_sortir",
        text: "No Sortir",
        footer: (cell, row, num, index) => {
          return <div>Total Transaksi : {datasortir.length}</div>;
        },
      },
      {
        dataField: "kondisi",
        text: "Kondisi",
        footer :""
      },
      {
        dataField: "kode_baki",
        text: "Kode Baki",
        footer :""
      },
      {
        dataField: "total_qty",
        headerClasses: "text-right",
        text: "Total Qty",
        formatter: (cell) => {
          return <div className="text-right"> {cell} </div>;
        },
        footer: (columnData) => {
          return (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          );
        },

      },
      {
        dataField: "total_berat",
        text: "Total Berat",
        headerClasses: "text-right",
        formatter: (cell) => {
          return <div className="text-right"> {formatGram(cell, 3)} </div>;
        },
        footer: (columnData) => {
          return (
            <div className="text-right">
              {" "}
              {formatGram(
                columnData.reduce((acc, item) => acc + item, 0),
                3
              )}{" "}
            </div>
          );
        },
      },
      {
        dataField: "action",
        text: "Action",
        csvExport: false,
        headerClasses: "text-center",
        formatter: (rowcontent, row) => {
          return (
            <div className="row text-center">
              <div className="col-12">
                <button
                type="button"
                  onClick={() => this.reprint(row)}
                  className="btn btn-primary mr-3"
                >
                  {" "}
                  <i className="fa fa-print"></i>
                </button>
              </div>
            </div>
          );
        },
      },
    ];
    const columns = [
      {
        dataField: "no_sortir",
        text: "No Sortir",
        footer: (cell, row, num, index) => {
          return <div>Total Transaksi : {datasortir.length}</div>;
        },
      },
      {
        dataField: "kondisi",
        text: "Kondisi",
        footer: "",
      },
      {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        formatter: (cell) => {
          return <div className="text-right"> {formatGram(cell, 3)} </div>;
        },
        footer: (columnData) => {
          return (
            <div className="text-right">
              {" "}
              {formatGram(
                columnData.reduce((acc, item) => acc + item, 0),
                3
              )}{" "}
            </div>
          );
        },
      },
      {
        dataField: "tujuan",
        text: "Baki Tujuan",
      },
    ];
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
        <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="type"
              component={ReanderSelect}
              onChange={(e) =>this.props.changeType(e)}
              // onChange={(e)=>this.props.onChange(e)}
              options={[
                { value: "GLOBAL", name: "GLOBAL" },
                { value: "DETAIL", name: "DETAIL" },
              ]}
              label="Type Laporan"
              placeholder="Pilih Type"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kode_dept"
              component={ReanderSelect}
              options={DataJenis}
              label="Kode Jenis"
              placeholder="Pilih Kode Jenis"
            />
          </div>
          <div className="col-lg-12">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_sortir"
              data={this.props.export ? datasortir : []}
              columns={this.props.type === "GLOBAL" ? columnsGlobal : columns}
              empty={true}
              textEmpty="Data Laporan Sortir Pembelian Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            {this.props.type === "GLOBAL" ? (
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <button
                    onClick={() => this.props.type === "GLOBAL" ? this.exportPdfGlobal() : this.exportPdfDetail()}
                    className="btn btn-warning btn-block"
                  >
                    {" "}
                    Export PDF{" "}
                  </button>
                  {/* <ExportTransaksiPembelianPdfHistory data={this.props.LaporanTransaksiPembelian} /> */}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <button
                    onClick={() => this.exportPdfDetail()}
                    className="btn btn-warning btn-block"
                  >
                    {" "}
                    Export PDF{" "}
                  </button>
                  {/* <ExportTransaksiPembelianPdfHistory data={this.props.LaporanTransaksiPembelian} /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanSortirPembelian = reduxForm({
  form: "HeadLaporanSortirPembelian",
  enableReinitialize: true,
  validate: ValidasiLaporanKeuangan,
})(HeadLaporanSortirPembelian);
// export default connect(null)(HeadLaporanSortirPembelian);
export default connect((state) => {
  return {
    datajenis: state.datamaster.getDataJenis,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadLaporanSortirPembelian);
