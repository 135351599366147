import {
    React,
    Link,
    Panel,
    PanelHeader,
    connect,
    reduxForm,
    ToastNotification,
    localStorageencryp,
    getDataNoTimeOut2,
    PanelBody,
    convertDate
  } from "../../../../components/helpers/library";
  import HeadKirimLabeling from "./HeadKirimLabeling";
  
  class LaporanKirimLabeling extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        dataterimabarang: [],
        databarang : []
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit(data) {
      this.setState({
        isLoading: true,
      });
      let data_post = {
        tgl_awal: convertDate(data.tgl_awal),
        tgl_akhir: convertDate(data.tgl_akhir),
        kode_toko : data.kode_baki
      };
      getDataNoTimeOut2("kirim-labeling/reports?kode_lokasi_toko="+data_post.kode_toko +'&tgl_to='+data_post.tgl_akhir+'&tgl_from='+data_post.tgl_awal)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
            this.setState({
              export: false,
              isLoading: false,
              dataterimabarang : [],
              databarang : []
            });
          } else {
            ToastNotification("success", "Laporan Terima Barang Tersedia");
            localStorageencryp("tgl_laporan", JSON.stringify(data_post));
            console.log(res.data);
            let detailBarang = [];
            res.data.forEach(detail => {
              detail.detail.forEach(list => {
                let row = {
                    _id: list._id,
                    no_kirim_stock: list.no_kirim_stock,
                    no_terima_sepuh: list.no_terima_sepuh,
                    kode_lokasi_toko: list.kode_lokasi_toko,
                    kode_dept: list.kode_dept,
                    qty: list.qty,
                    berat: list.berat,
                    qty_kembali: list.qty_kembali,
                    berat_kembali: list.berat_kembali,
                    tgl_system: list.tgl_system
                }
                detailBarang.push(row)
              });
            });
            this.setState({
              dataterimabarang: [],
              databarang: [],
            });
            this.setState({
              export: true,
              isLoading: false,
              databarang : res.data,
              dataterimabarang: detailBarang,
            });
          }
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
            dataterimabarang : [],
            databarang : []
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Laporan Sepuhan</Link>
            </li>
            <li className="breadcrumb-item active">
              Laporan Kirim Labeling
            </li>
          </ol>
          <h1 className="page-header">Laporan Kirim Labeling </h1>
          <Panel>
            <PanelHeader>Laporan Kirim Labeling</PanelHeader>
            <br />
            {/* Laporan Kirim Barang Dari Bos */}
            <PanelBody>
              <HeadKirimLabeling
                export={this.state.export}
                isLoading={this.state.isLoading}
                dataterimabarang={this.state.dataterimabarang}
                databarang={this.state.databarang}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            </PanelBody>
            <br />
            {/* End Tambah Laporan Kirim Barang Dari Bos  */}
          </Panel>
        </div>
      );
    }
  }
  
  LaporanKirimLabeling = reduxForm({
    form: "LaporanKirimLabeling",
    enableReinitialize: true,
  })(LaporanKirimLabeling);
  export default connect()(LaporanKirimLabeling);
  