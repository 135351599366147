import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanBahanTukangRekapExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="LAPORAN BAHAN TUKANG REKAP"
          sheet="LAPORAN BAHAN TUKANG REKAP"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="6" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN BAHAN TUKANG REKAP{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="6"> Periode </th>
            </tr>
            <tr>
              <th colSpan="6">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="6"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="6"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE TUKANG
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>KATEGORI</td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                TOTAL BAHAN
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                PAKAI BAHAN
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                PATRI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SISA BAHAN
              </td>
            </tr>
          </thead>
          <tbody>
          {/* {console.log(this.props.dataexel.length)} */}
            {this.props.dataexel.map((row, index) => (
                <tr key={index}>
                  <td>{row.kode_tukang}</td>
                  <td>{row.kode_kategori}</td>
                  <td style={{ textAlign: "right" }}>
                  &nbsp;{row.total_bahan}
                  </td>
                  <td style={{ textAlign: "right" }}>
                  &nbsp; {row.pakai_bahan.toFixed(3)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                  &nbsp; {row.patri}
                  </td>
                  <td style={{ textAlign: "right" }}>
                  &nbsp; {row.sisa_bhn.toFixed(3)}
                  </td>
                </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="2"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Grand Total :
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;{this.props.dataexel
                  .map((list) => list.total_bahan)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;{this.props.dataexel
                  .map((list) => list.pakai_bahan)
                  .reduce((a, b) => a + b, 0).toFixed(3)
                  }{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;{this.props.dataexel
                  .map((list) => list.patri)
                  .reduce((a, b) => a + b, 0)
                  }{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;{this.props.dataexel
                  .map((list) => list.sisa_bhn)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(3)}{" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanBahanTukangRekapExel;
