import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { hideModal } from "../../../actions/datamaster_action";
import {
  HiidenFiled,
  ReanderField,
  ReanderSelect,
} from "../../../components/helpers/field";
import ValidasiMasterKondisiPembelian from "../../../Validasi/ValidasiMasterKondisiPembelian.jsx";
import Loading from "react-fullscreen-loading";
import { createNumberMask } from "redux-form-input-masks";

const maptostate = (state) => {
  if (state.datamaster.ShowModalKondisiPembelian !== null) {
    return {
      DataGroup: state.datamaster.GetDataGroup,
      initialValues: {
        id: state.datamaster.ShowModalPotonganKategori.id,
        kode_group:
          state.datamaster.ShowModalPotonganKategori.kode_group,
        kode_group_lama:
          state.datamaster.ShowModalPotonganKategori.kode_group,
        potongan_harga:
          state.datamaster.ShowModalPotonganKategori.potongan_harga,
        potongan_markis:
          state.datamaster.ShowModalPotonganKategori.potongan_markis,
      },
    };
  }
};
const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "kr-KO",
});
class FormModalPotonganKategori extends Component {
  state = {
    aktif: false,
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <input type="hidden" name="kondisi_barang_lama" />
        {this.props.isEdit ? (
          <>
            <Field
              name="id"
              component={HiidenFiled}
              type="hidden"
              readOnly={this.props.isEdit}
            />
          </>
        ) : null}
        <Field
          tabIndex="1"
          name="kode_group"
          component={ReanderSelect}
          options={this.props.DataGroup.map((list) => {
            let data = {
              value: list.kode_group,
              name: list.kode_group + " - " + list.nama_group,
            };
            return data;
          })}
          disabled={this.props.isEdit}
          label="Kode Group"
          placeholder="Silahkan Pilih Kode Group"
        />
        <Field
          tabIndex="2"
          id="potongan_harga"
          name="potongan_harga"
          component={ReanderField}
          type="text"
          {...currencyMask}
          label="Potongan Harga"
          onBlur={() => this.setState({ aktif: true })}
          placeholder="Masukan Potongan Harga"
        />
        <Field
          tabIndex="3"
          id="potongan_markis"
          name="potongan_markis"
          component={ReanderField}
          type="number"
          label="Markis (%)"
          onBlur={() => this.setState({ aktif: true })}
          placeholder="Masukan Jumlah Markis"
        />
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="3"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormModalPotonganKategori = reduxForm({
  form: "ModalKondisiPembelian",
  enableReinitialize: true,
  validate: ValidasiMasterKondisiPembelian,
})(FormModalPotonganKategori);
export default connect(maptostate, null)(FormModalPotonganKategori);
