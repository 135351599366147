import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelHeader } from "../../../components/panel/panel.jsx";
import {
  ShowModalPotonganKategori,
  showModal,
  getPotonganKategori,
  hideModal,
  GetDataGroup,
  getMasterToko,
} from "../../../actions/datamaster_action.jsx";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  NotifSucces,
  ToastNotification,
} from "../../../components/helpers/notification.jsx";
import { deleteData, postData, putData } from "../../../config/axios.jsx";
import { reset } from "redux-form";
import { LoopingDeleteData, LoopingEditData, LoopingSendData } from "../../../components/helpers/function.jsx";
import Tabel from "../../../components/helpers/tabel.jsx";
import ModalGlobal from "../../../components/helpers/ModalGlobal.jsx";
import FormModalPotonganKategori from "./FormModalPotonganKategori.jsx";

const maptostate = (state) => {
  return {
    GetDataPotonganKategori: state.datamaster.GetDataPotonganKategori,
    hideModal: state.datamaster.modalDialog,
  };
};
const hapusDataKondisiPembelian = (params, dispatch) => {
  Swal.fire({
    html:
      "Apakah Anda Yakin Ingin " +
      "Menghapus " +
      "<h1><b>Kode Kondisi Pembelian " +
      params.kode_group +
      "</b> ?</h1>",
    icon: "warning",
    position: "top-center",
    cancelButtonText: "Tidak",
    showCancelButton: true,
    confirmButtonText: "OK",
    showConfirmButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      deleteData("parameter-potongan/" + params._id).then(() => {
        NotifSucces("Data Berhasil Di Hapus").then(() =>
          dispatch(getPotonganKategori())
        );
      }).then(()=> dispatch(LoopingDeleteData("parameter-potongan/delete", {kode_group : params.kode_group})))
    }
  }).catch((err) => {
    ToastNotification("error", err.response.data)
  })
};
class MasterPotonganKategori extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isLoading: false,
      modalDialog: false,
      columns: [
        {
          dataField: "kode_group",
          text: "Kode Group",
          sort: true,
        },
        {
          dataField: "potongan_harga",
          text: "Potongan Harga",
          formatter: (cell) => {
            // console.log(cell);
            return cell===undefined ? 0 : cell.toLocaleString("kr-KO");
          },
        },
        {
          dataField: "potongan_markis",
          text: "Potongan Markis",
          formatter: (cell) => {
            // console.log(cell);
            return cell + " %";
          },
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            let dataEdit = {
              id: row._id,
              kode_group: row.kode_group,
              potongan_harga: row.potongan_harga,
              potongan_markis: row.potongan_markis,
            };

            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => this.formEditPotonganKategori(dataEdit)}
                    className="btn btn-primary mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    onClick={() =>
                      hapusDataKondisiPembelian(
                        row,
                        this.props.dispatch
                      )
                    }
                    className="btn btn-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getPotonganKategori());
    this.props.dispatch(GetDataGroup())
    this.props.dispatch(getMasterToko())
  }
  handleSubmit(data) {
    this.setState({ isLoading: true });
    let data_edit = {
      kode_group: data.kode_group,
      potongan_harga: data.potongan_harga,
      potongan_markis: data.potongan_markis,
    };
    let data_post = {
      kode_group: data.kode_group,
      potongan_harga: data.potongan_harga,
      potongan_markis: data.potongan_markis,
    };
    this.state.isEdit
      ? putData(
          "parameter-potongan/" + data.id,
          data_edit
        )
          .then(() => {
            this.props.dispatch(hideModal());
          })
          .then(() => {
            ToastNotification("success", "Data Berhasil Diedit");
          })
          .then(() => {
            this.props.dispatch(getPotonganKategori());
          })
          .then(() => {
            this.setState({ isLoading: false });
          })
          .then(() => {
            this.setState({ isLoading: false });
            this.props.dispatch(reset("ModalKondisiPembelian"));
          })
          .then(()=> {
            this.props.dispatch(LoopingEditData("parameter-potongan",
            data_edit))
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            ToastNotification("info", error.response === undefined ? "Terjadi Kesalahan Saat Megirim Data " : error.response.data)
          })
      : postData("parameter-potongan", data_post)
          .then(() => {
            this.props.dispatch(hideModal());
          })
          .then(() => {
            ToastNotification("success", "Data Berhasil Disimpan");
          })
          .then(() => {
            this.props.dispatch(getPotonganKategori());
          })
          .then(() => {
            this.props.dispatch(reset("ModalKondisiPembelian"));
          })
          .then(() => {
            this.setState({ isLoading: false });
          }).then((()=> this.props.dispatch(LoopingSendData("parameter-potongan", data_post))))
          .catch((error) => {
            this.setState({ isLoading: false });
            ToastNotification("info", error.response === undefined ? "Terjadi Kesalahan Saat Megirim Data " : error.response.data)
          })
  }

  formEditPotonganKategori(dataEdit) {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowModalPotonganKategori(dataEdit));
    this.setState({
      isEdit: true,
    });
  }
  ShowModalPotonganKategori() {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowModalPotonganKategori(false));
    this.setState({
      isEdit: false,
    });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Master Parameter Potongan Kategori</li>
        </ol>
        <h1 className="page-header">Master Parameter Potongan Kategori </h1>
        <Panel>
          <PanelHeader>Master Parameter Potongan Kategori</PanelHeader>
          <br />

          {/* Master Kondisi Pembelian */}
          <PanelBody>
            <Tabel
              keyField="kondisi_barang"
              tambahData={true}
              handleClick={() => this.ShowModalPotonganKategori()}
              exportCsv={true}
              data={this.props.GetDataPotonganKategori}
              columns={this.state.columns}
              empty={this.props.GetDataPotonganKategori}
              textEmpty="Data Parameter Potongan Kategori Kosong"
            />
          </PanelBody>
          <br />
          {/* End Master Sales */}
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit
                ? "Edit Data Parameter Potongan Kategori"
                : "Tambah Data Parameter Potongan Kategori"
            }
            content={
              <FormModalPotonganKategori
                isEdit={this.state.isEdit}
                isLoading={this.state.isLoading}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            }
          />

          {/* End Tambah Master Kondisi Pembelian  */}
        </Panel>
      </div>
    );
  }
}

export default connect(maptostate, null)(MasterPotonganKategori);
