import Axios from "axios";
import Swal from "sweetalert2";
import { localStoragedecryp } from "../components/helpers/function";
import { ToastNotification } from "../components/helpers/notification";

const server = process.env.REACT_APP_BACKEND_URL;
const server2 = process.env.REACT_APP_BACKEND_URL_2;
const tokenPusat = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiUFVTQVQiLCJsZXZlbCI6IlNVIiwiaWF0IjoxNjQyNDAyMDg4fQ.TWq2eBf_HAtwYzeomz8KA7PJM0151iafJsJjQmz2Zxs";
// 192.168.1.22
// 188.166.254.162
let timeout_getData = "";
let timeout_getData2 = "";
let timeout_postData = "";
let timeout_postData2 = "";
let timeout_putData = "";
let timeout_putData2 = "";
let timeout_deleteData = "";

export function putDataNoTImeOut2(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
  };

  return new Promise((resolve, reject) => {
    Axios.put(server2 + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
export function postDataNoTImeOut2(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
  };

  return new Promise((resolve, reject) => {
    Axios.post(server2 + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function postDataKirimCabang(endpoint, data, kode_cabang) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token"), "kode_cabang" : kode_cabang },
  };

  return new Promise((resolve, reject) => {
    Axios.post(server2 + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function postLoop(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": tokenPusat },
  };

  return new Promise((resolve, reject) => {
    Axios.post(endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
          reject(err);
        
      });
  });
}

export function putLoop(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": tokenPusat },
  };

  return new Promise((resolve, reject) => {
    Axios.put(endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      });
  });
}
export function deleteLoop(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": tokenPusat },
  };

  return new Promise((resolve, reject) => {
    Axios.put(endpoint,data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      });
  });
}

export function getDataNoTimeOut2(endpoint) {
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
  };
  return new Promise((resolve, reject) => {
    Axios.get(server2 + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}
export function getDataNoTimeOut(endpoint) {
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
  };
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}

export function getDataWithQuery(endpoint, query) {
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    params: query
  };
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          clearTimeout(timeout_getData);
          reject(err);
        }
      });
  });
}
export function getData(endpoint) {
  clearTimeout(timeout_getData);
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };
  return new Promise((resolve, reject) => {
    timeout_getData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData);
            reject(err);
          }
        }
      });
  });
}
export function getDataParams(endpoint, params) {
  clearTimeout(timeout_getData2);
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
    params: params,
  };
  return new Promise((resolve, reject) => {
    timeout_getData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData2);
            reject(err);
          }
        }
      });
  });
}
export function getDataParams2(endpoint, params) {
  clearTimeout(timeout_getData2);
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
    params: params,
  };
  return new Promise((resolve, reject) => {
    timeout_getData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server2 + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData2);
            reject(err);
          }
        }
      });
  });
}
export function getData2(endpoint) {
  clearTimeout(timeout_getData2);
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };
  return new Promise((resolve, reject) => {
    timeout_getData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.get(server2 + endpoint, config)
      .then((res) => {
        clearTimeout(timeout_getData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_getData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_getData2);
            reject(err);
          }
        }
      });
  });
}
export function postData2(endpoint, data) {
  clearTimeout(timeout_postData2);
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_postData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.post(server2 + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_postData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_postData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_postData2);
            reject(err);
          }
        }
      });
  });
}

export function putData2(endpoint, data) {
  clearTimeout(timeout_putData2);
  // localStorage.setItem("daaa", JSON.stringify(data));
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_putData2 = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.put(server2 + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_putData2);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_putData2);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_putData2);
            reject(err);
          }
        }
      });
  });
}

export function postDataNoTImeOut(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
  };

  return new Promise((resolve, reject) => {
    Axios.post(server + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}
export function putDataNoTImeOut(endpoint, data) {
  // clearTimeout(timeout_postData)
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
  };

  return new Promise((resolve, reject) => {
    Axios.put(server + endpoint, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        var hasil = /Invalid Token./i.test(
          err && err.response && err.response.data
        );
        if (hasil) {
          window.location.reload();
          localStorage.clear();
          localStorage.setItem("islogin", "false");
        } else {
          reject(err);
        }
      });
  });
}

export function postData(endpoint, data) {
  clearTimeout(timeout_postData);
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_postData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.post(server + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_postData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_postData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_postData);
            reject(err);
          }
        }
      });
  });
}

export function deleteData(endpoint, data) {
  clearTimeout(timeout_deleteData);
  let hasil = {
    data: data,
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_deleteData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.delete(server + endpoint, hasil)
      .then((res) => {
        clearTimeout(timeout_deleteData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_deleteData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_deleteData);
            reject(err);
          }
        }
      });
  });
}
export function putData(endpoint, data) {
  clearTimeout(timeout_putData);
  // localStorage.setItem("daaa", JSON.stringify(data));
  let config = {
    headers: { "x-auth-token": localStoragedecryp("token") },
    timeout: 5000,
  };

  return new Promise((resolve, reject) => {
    timeout_putData = setTimeout(() => {
      Swal.fire({
        position: "center",
        imageUrl: require("../assets/img/LostConnection.gif"),
        title: "Gagal Terhubung Ke Server",
        text: "Silahkan Coba Lagi",
        confirmButtonText: "Coba Lagi",
        confirmButtonColor: "#558776",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      reject("TIMEOUT");
    }, 5000);
    Axios.put(server + endpoint, data, config)
      .then((res) => {
        clearTimeout(timeout_putData);
        resolve(res);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          clearTimeout(timeout_putData);
          reject(err);
        } else {
          var hasil = /Invalid Token./i.test(
            err && err.response && err.response.data
          );
          if (hasil) {
            window.location.reload();
            localStorage.clear();
            localStorage.setItem("islogin", "false");
          } else {
            clearTimeout(timeout_putData);
            reject(err);
          }
        }
      });
  });
}

export function checkTimeout(err) {
  if (new RegExp("timeout", "g").test(err)) {
    ToastNotification("info", "Koneksi bermasalah..");
    return false;
  } else {
    return true;
  }
}
