import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { InputDate, ReanderField } from "../../../components/helpers/field";
import { getDataSales } from "../../../actions/datamaster_action";
import { getData, getDataParams2 } from "../../../config/axios";
import { ToastNotification } from "../../../components/helpers/notification";
import Tabel from "../../../components/helpers/tabel";
import {
  disableButtonValidasi,
  saveValidasiBeli,
  saveValidasiJual,
} from "../../../actions/hutang_action";
import Loading from "react-fullscreen-loading";
import {
  convertDate,
  getTglSystem,
} from "../../../components/helpers/function";

const selector = formValueSelector("HeadValidasiInputanHutang");
const maptostate = (state) => {
  const { jenis_transaksi, tgl_awal, tgl_akhir, no_kirim } = selector(
    state,
    "jenis_transaksi",
    "tgl_awal",
    "tgl_akhir",
    "no_kirim"
  );
  return {
    jenis_transaksi: jenis_transaksi,
    tgl_awal: tgl_awal,
    tgl_akhir: tgl_akhir,
    no_kirim: no_kirim,
    DataSales: state.datamaster.getDataSales,
    saveValidasiBeli: state.hutang.saveValidasiBeli,
    saveValidasiJual: state.hutang.saveValidasiJual,
    btnValidasi: state.hutang.disableButtonValidasi,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
};
class HeadValidasiBarangRusak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      DataValidasi: [],
      btnValidasi: false,
      kode_sales: false,
      warna: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataSales());
    // this.props.dispatch(disableButtonValidasi());
    this.props.change("tgl_awal",getTglSystem())
    this.props.change("tgl_akhir",getTglSystem())
    if (
      this.props.saveValidasiJual.length === 0 &&
      this.props.saveValidasiBeli.length === 0
    ) {
      localStorage.setItem("validasi_rusak", JSON.stringify([]));
      localStorage.setItem("data_validasi_rusak", JSON.stringify([]));
    }
  }
  getDataSales(e) {
    localStorage.setItem("validasi_rusak", JSON.stringify([]));
    localStorage.setItem("validasi_beli", JSON.stringify([]));
    this.setState({
      kode_sales: e,
    });
    getData("sales/get/by-kode-sales/" + e)
      .then((res) => {
        // localStorage.setItem('datausersvalidasi',JSON.stringify(res.data))
        this.props.change("nama_sales", res.data.nama_sales);
        this.props.dispatch(saveValidasiJual([]));
        this.props.dispatch(saveValidasiBeli([]));
      })
      .catch((err) => {
        ToastNotification("info", "Gagal Mengambil Data Keserver");
      });
  }
  getDetaValidasi() {
    this.setState({
      isLoading: true,
    });
    const query = {
      tgl_from: convertDate(this.props.tgl_awal || getTglSystem()),
      tgl_to: convertDate(this.props.tgl_akhir || getTglSystem()),
      no_kirim_bos: this.props.no_kirim,
    };
    getDataParams2("barang-rusak", query)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Belum Ada Data Yang Harus Di Validasi");
          this.setState({
            isLoading: false,
          });
          // this.props.change("grandtotal", 0);
        } else {
          let harga_total = 0;
          res.data.forEach((element) => {
            harga_total = harga_total + element.harga_total;
          });

          // this.props.change(
          //   "grandtotal",
          //   harga_total.toLocaleString("kr-KO")
          // );
          ToastNotification("success", "Data Validasi Tersedia");
          this.setState({
            isLoading: false,
          });
          this.props.dispatch(disableButtonValidasi(true));
          localStorage.setItem("validasi_rusak", JSON.stringify(res.data));
          this.props.dispatch(saveValidasiJual(res.data));
        }
      })
      .catch((err) => {
        ToastNotification("info", "Terjadi Kesalahan Saat Request");
        this.setState({
          isLoading: false,
          DataValidasi: [],
        });
      });
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  render() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selectColumnPosition: "right",
      onSelect: (row, isSelect, rowIndex, e) => {
        // console.log(isSelect);
        if (isSelect === true) {
          let hasil = {
            no_sepuh: row.no_sepuh || row.no_sortir,
            berat_total: row.berat_total,
            qty_total: row.qty_total,
            qty_rusak: row.qty_rusak ?? 0,
            berat_rusak: row.berat_rusak ?? 0,
          };
          let cek =
            JSON.parse(localStorage.getItem("data_validasi_rusak")) || [];
          let hasil_cek = cek.findIndex(
            (hasil) =>
              hasil.no_sepuh === row.no_sepuh ||
              hasil.no_sepuh === row.no_sortir
          );
          if (hasil_cek === -1) {
            cek.push(hasil);
            localStorage.setItem("data_validasi_rusak", JSON.stringify(cek));
          }
        } else {
          // console.log();
          if (localStorage.getItem("data_validasi_rusak") === null) {
          } else {
            let data = JSON.parse(localStorage.getItem("data_validasi_rusak"));
            data.splice(row.no_sepuh, 1);
            localStorage.setItem("data_validasi_rusak", JSON.stringify(data));
          }
        }
      },
      onSelectAll: (isSelect, data, e) => {
        data.forEach((list, index) => {
          if (isSelect === true) {
            let hasil = {
              no_sepuh: list.no_sepuh || list.no_sortir,
              berat_total: list.berat_total,
              qty_total: list.qty_total,
              qty_rusak: list.qty_rusak ?? 0,
              berat_rusak: list.berat_rusak ?? 0,
            };
            let cek =
              JSON.parse(localStorage.getItem("data_validasi_titip")) || [];
            let hasil_cek = cek.findIndex(
              (hasil) =>
                hasil.no_sepuh === list.no_sepuh ||
                hasil.no_sepuh === list.no_sortir
            );
            if (hasil_cek === -1) {
              cek.push(hasil);
              localStorage.setItem("data_validasi_titip", JSON.stringify(cek));
            }
          } else {
            if (localStorage.getItem("data_validasi_titip") === null) {
            } else {
              let data = JSON.parse(
                localStorage.getItem("data_validasi_titip")
              );
              data.splice(list.no_sepuh, 1);
              localStorage.setItem("data_validasi_titip", JSON.stringify(data));
            }
          }
        });
      },
    };

    let datasalses = [
      {
        kode_sales: "SEMUA",
        nama_sales: "SEMUA",
      },
    ];
    this.props.DataSales.forEach((element) => {
      let row = {
        kode_sales: element.kode_sales,
        nama_sales: element.nama_sales,
      };
      datasalses.push(row);
    });

    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-3">
            <Field
              name="no_kirim"
              component={ReanderField}
              type="text"
              label="Nomor Kirim"
              placeholder="Masukan Nomor Kirim"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-3 mb-3">
            <label>&nbsp;</label>
            <button
              onClick={() => this.getDetaValidasi()}
              className="btn btn-primary btn-block"
              disabled={this.state.isLoading}
              type="button"
            >
              {this.state.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Cari Data
                </>
              ) : (
                "Cari Data"
              )}
            </button>
          </div>
          <div className="col-12">
            <Tabel
              selectRow={selectRow}
              keyField="_id"
              columns={this.props.columnsJual}
              data={this.props.saveValidasiJual || []}
              empty={true}
            />
          </div>

          <div className="col-6">
            {this.props.btnValidasi === false ? (
              <button
                className="btn btn-primary btn-block"
                disabled={true}
                type="button"
              >
                Validasi
              </button>
            ) : (
              <button
                className="btn btn-primary btn-block"
                disabled={this.props.isLoading}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Validasi
                  </>
                ) : (
                  "Validasi"
                )}
              </button>
            )}
          </div>
          <div className="col-6">
            <button
              className="btn btn-warning btn-block"
              disabled={this.props.isLoadingBtl}
              onClick={() => this.props.btnClear()}
              type="button"
            >
              {this.props.isLoadingBtl ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Batal
                </>
              ) : (
                "Batal"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadValidasiBarangRusak = reduxForm({
  form: "HeadValidasiInputanHutang",
  enableReinitialize: true,
})(HeadValidasiBarangRusak);
export default connect(maptostate)(HeadValidasiBarangRusak);
