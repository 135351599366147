import {
    React,
    Link,
    Panel,
    PanelHeader,
    connect,
    reduxForm,
    ToastNotification,
    localStorageencryp,
    getDataNoTimeOut2,
    PanelBody,
    convertDate
  } from "../../../../components/helpers/library";
  import HeadForm from "./HeadForm";
  
  class LaporanTerimaBarangSepuh extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        dataterimabarang: [],
        databarang : []
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit(data) {
      this.setState({
        isLoading: true,
      });
      let data_post = {
        tgl_awal: convertDate(data.tgl_awal),
        tgl_akhir: convertDate(data.tgl_akhir),
        kode_toko : data.kode_baki
      };
      getDataNoTimeOut2("terima-sepuh/reports?kode_toko="+data_post.kode_toko +'&tgl_to='+data_post.tgl_akhir+'&tgl_from='+data_post.tgl_awal)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
            this.setState({
              export: false,
              isLoading: false,
              dataterimabarang : [],
              databarang : []
            });
          } else {
            ToastNotification("success", "Laporan Terima Barang Tersedia");
            localStorageencryp("tgl_laporan", JSON.stringify(data_post));
           
            let detailBarang = [];
            res.data.forEach(detail => {
              detail.detail.forEach(list => {
                let row = {
                    _id: list._id,
                    no_terima_sepuh: list.no_terima_sepuh,
                    no_sepuh: list.no_sepuh,
                    tgl_terima: list.tgl_terima,
                    kode_baki: list.kode_baki,
                    kode_dept: list.kode_dept,
                    qty_sepuh: list.qty_sepuh,
                    berat_timbangan: list.berat_timbangan,
                    qty_terima: list.qty_terima,
                    berat_terima: list.berat_terima,
                    berat_rusak: list.berat_rusak,
                    qty_rusak: list.qty_rusak,
                    berat_selisih: list.berat_selisih,
                    berat_kirim: list.berat_kirim,
                    qty_kirim:list.qty_kirim
                }
                detailBarang.push(row)
              });
            });
            this.setState({
              dataterimabarang: [],
              databarang: [],
            });
            this.setState({
              export: true,
              isLoading: false,
              databarang : res.data,
              dataterimabarang: detailBarang,
            });
          }
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
            dataterimabarang : [],
            databarang : []
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Laporan Sepuhan</Link>
            </li>
            <li className="breadcrumb-item active">
              Laporan Terima Barang Sepuh
            </li>
          </ol>
          <h1 className="page-header">Laporan Terima Barang Sepuh </h1>
          <Panel>
            <PanelHeader>Laporan Terima Barang Sepuh</PanelHeader>
            <br />
            {/* Laporan Kirim Barang Dari Bos */}
            <PanelBody>
              <HeadForm
                export={this.state.export}
                isLoading={this.state.isLoading}
                dataterimabarang={this.state.dataterimabarang}
                databarang={this.state.databarang}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            </PanelBody>
            <br />
            {/* End Tambah Laporan Kirim Barang Dari Bos  */}
          </Panel>
        </div>
      );
    }
  }
  
  LaporanTerimaBarangSepuh = reduxForm({
    form: "LaporanTerimaBarangSepuh",
    enableReinitialize: true,
  })(LaporanTerimaBarangSepuh);
  export default connect()(LaporanTerimaBarangSepuh);
  