import {
  React,
  Link,
  Panel,
  PanelHeader,
  connect,
  reduxForm,
  getData,
  ToastNotification,
  PanelBody,
} from "../../../../components/helpers/library";
import HeadForm from "./HeadForm";

class LaporanSaldoAktifKirimBarangBos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dataterimabarang: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });

    getData(`krmbrgbos/report/saldo?no_kirim_bos=${data.no_kirim_bos || "ALL"}&kode_labeling=${data.kode_labeling}`)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
          this.setState({
            export: false,
            isLoading: false,
          });
        } else {
          ToastNotification("success", "Laporan Saldo Aktif Kirim Barang Dari Bos");
          this.setState({
            export: true,
            isLoading: false,
            dataterimabarang: res.data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          export: false,
          isLoading: false,
        });
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Stock Pembelian</Link>
          </li>
          <li className="breadcrumb-item active">
            Laporan Kirim Barang Dari Bos
          </li>
        </ol>
        <h1 className="page-header">
          Laporan Saldo Aktif Kirim Barang Dari Bos{" "}
        </h1>
        <Panel>
          <PanelHeader>Laporan Saldo Aktif Kirim Barang Dari Bos</PanelHeader>
          <br />
          {/* Laporan Kirim Barang Dari Bos */}
          <PanelBody>
            <HeadForm
              export={this.state.export}
              isLoading={this.state.isLoading}
              dataterimabarang={this.state.dataterimabarang}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
          {/* End Tambah Laporan Kirim Barang Dari Bos  */}
        </Panel>
      </div>
    );
  }
}

LaporanSaldoAktifKirimBarangBos = reduxForm({
  form: "LaporanSaldoAktifKirimBarangBos",
  enableReinitialize: true,
})(LaporanSaldoAktifKirimBarangBos);
export default connect()(LaporanSaldoAktifKirimBarangBos);
