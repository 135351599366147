import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanPesananSoExek extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let hasil = this.props.data.map((list)=>{
      let jml = parseFloat(list.saldo_awal) - parseFloat(list.berat_pakai) - parseFloat(list.susut_pakai);
  
      return jml.toLocaleString("id-ID")
  
    })
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Pesanan So"
          sheet="Laporan Pesanan So"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="10" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN PESANAN SO{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="10"> Periode </th>
            </tr>
            <tr>
              <th colSpan="10">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="10"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="10"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO PRESKOT
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE TUKANG
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                DESKRIPSI
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NAMA ATRIBUT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT ATRIBUT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT PAKAI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SUSUT PAKAI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ONGKOS
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ST SELESAI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ST SERAH
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((item, index) => (
              <>
                <tr key={index}>
                  <td
                    style={{ textAlign: "left", borderTop: "1px solid black" }}
                  >
                    {" "}
                    No Pesanan{" "}
                  </td>
                  <td
                    style={{ textAlign: "left", borderTop: "1px solid black" }}
                  >
                    {" "}
                    {item.no_so_pesanan}{" "}
                  </td>
                  <td
                    style={{ textAlign: "left", borderTop: "1px solid black" }}
                  >
                    {" "}
                    Kategori{" "}
                  </td>
                  <td
                    colSpan="7"
                    style={{ textAlign: "left", borderTop: "1px solid black" }}
                  >
                    {" "}
                    {item.kode_kategori}{" "}
                  </td>
                </tr>
                <tr key={index}>
                  <td style={{ borderBottom: "1px solid black" }}>
                    {" "}
                    Saldo Awal{" "}
                  </td>
                  <td
                    colSpan="9"
                    style={{
                      textAlign: "left",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {" "}
                    &nbsp;
                    {item.saldo_awal.toLocaleString("id-ID")}{" "}
                  </td>
                </tr>
                {item.pesanan.map((list, index2) => (
                  <>
                    <tr key={index2}>
                      <td> {list.no_pesanan} </td>
                      <td> {list.kode_tukang} </td>
                      <td> {list.nama_barang} </td>
                      <td> {list.nama_atribut} </td>
                      <td
                        style={{
                          color: "#000",
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        {list.berat_atribut}{" "}
                      </td>
                      <td
                        style={{
                          color: "#000",
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        {list.berat_pakai}{" "}
                      </td>
                      <td
                        style={{
                          color: "#000",
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        {list.susut_pakai}{" "}
                      </td>
                      <td
                        style={{
                          color: "#000",
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        &nbsp;
                        {list.ongkos.toLocaleString("kr-Ko")}{" "}
                      </td>
                      <td> {list.st_selesai} </td>
                      <td> {list.st_serah} </td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td
                    colSpan="4"
                    style={{ textAlign: "left", borderTop: "1px solid black" }}
                  >
                    {" "}
                  </td>
                  <td
                    style={{ textAlign: "right", borderTop: "1px solid black" }}
                  >
                    {" "}
                    {item.pesanan.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b.berat_atribut),
                      0
                    )}{" "}
                  </td>
                  <td
                    style={{ textAlign: "right", borderTop: "1px solid black" }}
                  >
                    {" "}
                    {item.pesanan.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b.berat_pakai),
                      0
                    )}{" "}
                  </td>
                  <td
                    style={{ textAlign: "right", borderTop: "1px solid black" }}
                  >
                    {" "}
                    {item.pesanan.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b.susut_pakai),
                      0
                    )}{" "}
                  </td>
                  <td
                    style={{ textAlign: "right", borderTop: "1px solid black" }}
                  >
                    {" "}
                    &nbsp;
                    {item.pesanan
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.ongkos), 0)
                      .toLocaleString("kr-Ko")}{" "}
                  </td>
                  <td
                    colSpan="2"
                    style={{ textAlign: "right", borderTop: "1px solid black" }}
                  ></td>
                </tr>
              </>
            ))}
          </tbody>

          <tfoot>
            <td
              colSpan="4"
              style={{ textAlign: "left", borderTop: "1px solid black" }}
            >
              Export By {getUserdata().user_id} / {getToday()}
            </td>
            <td
              colSpan="6"
              style={{ textAlign: "center", borderTop: "1px solid black" }}
            >
            Sisa Bahan : &nbsp; {hasil}
            </td>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanPesananSoExek;
