const ValidasiPesanan = (value) => {
    const errors = {};
    if (!value.no_pesanan) {
        errors.no_pesanan = "No Pesanan Harus Diisi";
    }
    if (!value.kode_tukang) {
        errors.kode_tukang = "Kode Tukang Harus Dipilih";
    }
    if (!value.ongkos_tukang) {
        errors.ongkos_tukang = "Ongkos Tukang Harus Diisi";
    }
    if (!value.berat_susut) {
        errors.berat_susut = "Berat Susut Harus Diisi";
    }
    if (!value.total_setor_bahan) {
        errors.total_setor_bahan = "Total Setor Bahan Harus Diisi";
    }
    if (!value.kategori) {
        errors.kategori = "Kategori Harus Diisi";
    }
    return errors;
}

export default ValidasiPesanan;


