import {
  GetDataGroup,
  getDataJenis,
  getDataKondisiBarang,
} from "../../../actions/datamaster_action";
import { formatGram, getItem, setItem } from "../../../components/helpers/function";
import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderSelect,
  ReanderField,
  getDataNoTimeOut2,
  Loading,
  Tabel,
  ToastNotification,
  postDataNoTImeOut2,
} from "../../../components/helpers/library";
import HeadGudangBaki from "./HeadGudangBaki";
import BuktirSortirSepuhan from "./Pdf/BuktirSortirSepuhan";
// import ValidasiGudangBaki from "../../../Validasi/Pembelian/ValidasiGudangBaki";

// let datajenis = [
//   {
//     value: "ALL",
//     name: "SEMUA",
//   },
// ];
class HeadSortirSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      datasortir: [],
      datajenis: [
       
      ],
      columns: [
        {
          dataField: "no_faktur_beli",
          text: "No Pembelian",
          footer: (cell, row, num, index) => {
            return (
              <div>Total Qty : {this.state.datasortir.length} </div>
            );
          },
          footerAttrs: {
            colSpan: "6",
          },
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },

        {
          dataField: "nama_barang",
          text: "Nama Barang",
          headerStyle: (colum, colIndex) => {
            return { width: "400px" };
          },
        },
        {
          dataField: "kondisi",
          text: "Kondisi",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        },

        {
          dataField: "kode_dept",
          text: "Jenis",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        },
        {
          dataField: "kode_group",
          text: "Kategori",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          },
          formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 2)} </div>;
          },
          footer: (columnData) => {
            return <div className="text-right"> { parseFloat(columnData.reduce((acc, item) => acc + item, 0)).toFixed(3)} </div>
          }
        },
        {
          dataField: "harga",
          text: "Harga",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right"> {cell.toLocaleString("kr-KO")} </div>
            );
          },
          footer: (columnData) => {
            return <div className="text-right"> { parseInt(columnData.reduce((acc, item) => acc + item, 0)).toLocaleString("kr-KO")} </div>
          }
        },
      ],
      columns2: [
        {
          dataField: "no_faktur_beli",
          text: "No Pembelian",
          footer: (cell, row, num, index) => {
            return (
              <div>Total Qty : {getItem("detail_sortir_sepuh").length} </div>
            );
          },
          footerAttrs: {
            colSpan: "6",
          },
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
          headerStyle: (colum, colIndex) => {
            return { width: "80px" };
          },
        },

        {
          dataField: "nama_barang",
          text: "Nama Barang",
          headerStyle: (colum, colIndex) => {
            return { width: "400px" };
          },
        },
        {
          dataField: "kondisi",
          text: "Kondisi",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        },

        {
          dataField: "kode_dept",
          text: "Jenis",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        },
        {
          dataField: "kode_group",
          text: "Kategori",
          headerStyle: (colum, colIndex) => {
            return { width: "70px" };
          },
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          },
          formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 2)} </div>;
          },
          footer: (columnData) => {
            return <div className="text-right"> { parseFloat(columnData.reduce((acc, item) => acc + item, 0)).toFixed(3)} </div>
          }
        },
        {
          dataField: "harga",
          text: "Harga",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right"> {cell.toLocaleString("kr-KO")} </div>
            );
          },
          footer: (columnData) => {
            return <div className="text-right"> { parseInt(columnData.reduce((acc, item) => acc + item, 0)).toLocaleString("kr-KO")} </div>
          }
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.print = this.print.bind(this);
  }
handleSubmit(data) {
  let no = getItem('no_ss');

    this.setState({
        isLoadingSortir: true,
      });
      // let filter_sortir = getItem("filter_sortir");
      if (data.kode_gudang === undefined) {
        ToastNotification("info", "Kode Gudang Harus Dipilih");
        this.setState({
          isLoadingSortir: false,
        });
        return false;
      }
      if (data.kode_baki === undefined) {
        ToastNotification("info", "Kode Baki Harus Dipilih");
        this.setState({
          isLoadingSortir: false,
        });
        return false;
      }
    let barang = [];
    if (getItem("detail_sortir_sepuh").length === 0) {
        ToastNotification("info", "Data Barang Sortir Belum Dipilih");
        this.setState({
          isLoadingSortir: false,
        });
        return false;
    }
  
      getItem("detail_sortir_sepuh").forEach((element) => {
        let row = {
          no_faktur_beli: element.no_faktur_beli,
          kode_dept: element.kode_dept,
          nama_barang: element.nama_barang,
          kode_group: element.kode_group,
          berat: element.berat,
          harga: element.harga,
          kondisi: element.kondisi,
          kode_lokasi_toko: element.kode_lokasi_toko,
          tgl_system: element.tgl_system,
        };
        barang.push(row);
      });
      let hasil = {
        kode_gudang: data.kode_gudang,
        kode_baki: data.kode_baki,
        detail_barang: barang,
      };
  
postDataNoTImeOut2("sortir-sepuh", hasil)
.then((res) => {
// console.log(res.data.buktiSlip)
setItem("detail_sortir_sepuh", []);
    
ToastNotification("success", res.data.message);
this.setState({
    isLoadingSortir: false,
    datasortir : []
});
this.cariTransaksi2(no);

BuktirSortirSepuhan(res.data)
let datasepuh = [];
for (let index = 0; index < 2; index++) {
let feedback = '';
let nama = "";
if(index===0){
nama= "INTERNAL";
}else{
nama= "LABEL";
}
feedback += `========================================\n`
feedback += `            SORTIR SEPUHAN              \n`
feedback += `               ${nama}                  \n`
feedback += `========================================
No Sortir        : ${res.data.buktiSlip.no_sortir}
Tgl Sortir       : ${res.data.buktiSlip.tgl_sortir}
kondisi          : ${res.data.buktiSlip.kondisi}
Kategori         : ${res.data.buktiSlip.kode_group}
Kode Baki        : ${res.data.buktiSlip.kode_baki}\n`
res.data.buktiSlip.jenis.forEach((row, index) => {
feedback += `Jenis             : ${row[Object.keys(row)[0]]}  ${Object.keys(row)[0]}\n`
})
feedback += `
Total Qty SP     : ${res.data.buktiSlip.qty_sp}
Total Berat SP   : ${res.data.buktiSlip.berat_sp}
`
feedback += `========================================\n`
feedback += `              TERIMA KASIH              \n`
feedback += `========================================`
feedback += `






VA\n`;
datasepuh.push(feedback);
}
// console.log(datasepuh);
document.getElementById("myInput").value = datasepuh.join('');
}).then(() => {
const element = document.createElement("a");
const file = new Blob([document.getElementById("myInput").value], {
type: "text/plain;charset=utf-8",
});
element.href = URL.createObjectURL(file);
element.download = "autoprint_pembelian.txt";
document.body.appendChild(element);
element.click();
}).then(() => {
setItem("detail_sortir_sepuh", []);
})
  .catch((err) => {
    console.log(err);
    ToastNotification("info", err.response?.data.message);
    this.setState({
      isLoadingSortir: false,
    });
  });
}
  componentDidMount() {
    this.props.dispatch(GetDataGroup());
    this.props.dispatch(getDataKondisiBarang());
      this.props.dispatch(getDataJenis());
      setTimeout(() => {
        document.getElementById("no_sortir").focus()
      }, 100);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }

  cariTransaksi(e) {
    setItem("no_ss",e)
    if(this.delayTimer){
      clearTimeout(this.delayTimer);
    }
    this.delayTimer = setTimeout(() => {
      this.setState({ isLoading: true });
      if (e === "") {
        this.setState({ isLoading: false, databarang: [] });
        return false;
      }
      getDataNoTimeOut2(
        "sortir-pembelian?no_sortir=" + e + "&kode_dept=ALL"
      ).then((res) => {
        // console.log(res.data);
        // this.props.change("kode_dept","ALL")
        this.setState({ isLoading: false, databarang: [] });
        this.props.change("tgl_awal", res.data.tgl_system);
        this.props.change("kode_group", res.data.kode_group);
        this.props.change("kondisi", res.data.kondisi);
        this.setState({
          datasortir: res.data.detail_sortir,
        });
        let hasil = [
        
        ];
        res.data.jenis.forEach((element) => {
          let row = {
            value: element,
            name: element,
          };
          hasil.push(row);
        });
        this.setState({
          datajenis: hasil,
        });
          this.props.change("kode_dept", res.data.jenis[0]);
      })
      .catch((err) => {
          ToastNotification("info",err.response?.data.message)
        this.setState({ isLoading: false, databarang: [] });
      })
    }, 1500);
  }
  cariTransaksi2(e) {
    setItem("no_ss",e)
      this.setState({ isLoading: true });
      if (e === "") {
        this.setState({ isLoading: false, databarang: [] });
        return false;
      }
      getDataNoTimeOut2(
        "sortir-pembelian?no_sortir=" + e + "&kode_dept=ALL"
      ).then((res) => {
        // console.log(res.data);
        // this.props.change("kode_dept","ALL")
        this.setState({ isLoading: false, databarang: [] });
        this.props.change("tgl_awal", res.data.tgl_system);
        this.props.change("kode_group", res.data.kode_group);
        this.props.change("kondisi", res.data.kondisi);
        this.setState({
          datasortir: res.data.detail_sortir,
        });
        let hasil = [
        
        ];
        res.data.jenis.forEach((element) => {
          let row = {
            value: element,
            name: element,
          };
          hasil.push(row);
        });
        this.setState({
          datajenis: hasil,
        });
          this.props.change("kode_dept", res.data.jenis[0]);
      })
      .catch((err) => {
          ToastNotification("info",err.response?.data.message)
        this.setState({ isLoading: false, databarang: [] });
      })
  }
  cariJenis(e) {
    getDataNoTimeOut2(
      "sortir-pembelian?no_sortir=" +
        document.getElementById("no_sortir").value +
        "&kode_dept=" +
        e
    ).then((res) => {
      console.log(res.data);
      this.setState({ isLoading: false, databarang: [] });
      this.props.change("tgl_awal", res.data.tgl_system);
      this.props.change("kode_group", res.data.kode_group);
      this.props.change("kondisi", res.data.kondisi);
      this.setState({
        datasortir: [],
      });
      this.setState({
        datasortir: res.data.detail_sortir,
      });
    });
  }
  render() {
    const sortirSepuh = {
      mode: "checkbox",
      clickToSelect: true,
      selectColumnPosition: "left",
      onSelect: (row, isSelect, rowIndex, e) => {
        // console.log(isSelect);
        this.setState({
          berhasil: true,
        });
        if (isSelect === true) {
          let hasil = {
            no_faktur_beli: row.no_faktur_beli,
            tgl_system: row.tgl_system,
            kondisi: row.kondisi,
            nama_barang: row.nama_barang,
            kode_dept: row.kode_dept,
            kode_group: row.kode_group,
            kode_lokasi_toko: row.kode_baki,
            berat: row.berat,
            harga: row.harga,
          };
          let cek =
            JSON.parse(localStorage.getItem("detail_sortir_sepuh")) || [];
          let hasil_cek = cek.findIndex(
            (hasil) => hasil.no_faktur_beli === row.no_faktur_beli
          );
          if (hasil_cek === -1) {
            cek.push(hasil);
            localStorage.setItem("detail_sortir_sepuh", JSON.stringify(cek));
          }
        } else {
          if (localStorage.getItem("detail_sortir_sepuh") === null) {
          } else {
            let data = JSON.parse(localStorage.getItem("detail_sortir_sepuh"));
            data.splice(row.no_faktur_beli, 1);
            localStorage.setItem("detail_sortir_sepuh", JSON.stringify(data));
          }
        }
      },
      onSelectAll: (isSelect, data, e) => {
        this.setState({
          berhasil: true,
        });
        data.forEach((list, index) => {
          if (isSelect === true) {
            let hasil = {
              no_faktur_beli: list.no_faktur_beli,
              tgl_system: list.tgl_system,
              kondisi: list.kondisi,
              nama_barang: list.nama_barang,
              kode_dept: list.kode_dept,
              kode_group: list.kode_group,
              kode_lokasi_toko: list.kode_baki,
              berat: list.berat,
              harga: list.harga,
            };
            let cek =
              JSON.parse(localStorage.getItem("detail_sortir_sepuh")) || [];
            let hasil_cek = cek.findIndex(
              (hasil) => hasil.no_faktur_beli === list.no_faktur_beli
            );
            if (hasil_cek === -1) {
              cek.push(hasil);
              localStorage.setItem("detail_sortir_sepuh", JSON.stringify(cek));
            }
          } else {
            if (localStorage.getItem("detail_sortir_sepuh") === null) {
            } else {
              let data = JSON.parse(
                localStorage.getItem("detail_sortir_sepuh")
              );
              data.splice(list.no_faktur_beli, 1);
              localStorage.setItem("detail_sortir_sepuh", JSON.stringify(data));
            }
          }
        });
      },
    };
      return (
          <>
        <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-4">
            <Field
              id="no_sortir"
              name="no_sortir"
              component={ReanderField}
              label="No Sortir Pembelian"
              type="text"
              onChange={(data) => this.cariTransaksi(data.target.value)}
              placeholder="Masukan No Sortir Pembelian"
            />
          </div>
          <div className="col-lg-2">
            <Field
              name="tgl_awal"
              component={ReanderField}
              label="Tanggal"
              type="text"
              readOnly={true}
              placeholder="Tanggal"
            />
          </div>

          <div className="col-lg-2">
            <Field
              name="kode_group"
              component={ReanderField}
              label="Kode Group"
              type="text"
              readOnly={true}
              placeholder="Kode Group"
            />
          </div>
          <div className="col-lg-2">
            <Field
              name="kondisi"
              component={ReanderField}
              label="Kondisi"
              type="text"
              readOnly={true}
              placeholder="Kondisi"
            />
          </div>

          <div className="col-2">
            <Field
              name="kode_dept"
              label="Kode Jenis"
              placeholder="Pilih Kode Jenis"
              onChange={(data) => this.cariJenis(data)}
              options={this.state.datajenis}
              component={ReanderSelect}
            />
          </div>

          <div className="col-12">
            <Tabel
              selectRow={sortirSepuh}
              keyField="no_faktur_beli"
              data={this.state.datasortir}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Barang Kosong"
            />
          </div>
        </div>
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
        <Tabel
          keyField="no_faktur_beli"
          data={getItem("detail_sortir_sepuh")}
          columns={this.state.columns2}
          empty={true}
          textEmpty="Data Barang"
            />
              </form>
              <HeadGudangBaki isLoadingSortir={this.state.isLoadingSortir} onSubmit={(data)=>this.handleSubmit(data)}  />
              
            {this.state.isLoadingSortir ? (
              <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
              />
            ) : null}

            </>
    );
  }
}

HeadSortirSepuh = reduxForm({
  form: "HeadSortirSepuh",
  enableReinitialize: true,
  // validate : ValidasiGudangBaki
})(HeadSortirSepuh);
// export default connect()(HeadSortirSepuh);
export default connect((state) => {
  return {
    DataGroup: state.datamaster.GetDataGroup,
    DataKondisi: state.datamaster.getDataKondisiBarang,
    DataJenis: state.datamaster.getDataJenis,
  };
})(HeadSortirSepuh);
