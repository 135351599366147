const ValidasiMasterKondisiPembelian = (value, event) => {
  const errors = {};
  // console.log(event.charCode==13);
  if (!value.kondisi_barang) {
    errors.kondisi_barang = "kondisi Tidak Boleh Kosong";
  }
  if (!value.persentase) {
    errors.persentase = "Presentase Tidak Boleh Kosong";
  }

  return errors;
};

export default ValidasiMasterKondisiPembelian;
