import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPesananSo = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("LAPORAN PESANAN SO", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN PESANAN SO",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
          " s/d " +
          JSON.parse(localStoragedecryp("tgl_laporan")).tgl_akhir
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `NO PERSEKOT`,
      },
      {
        content: `KODE TUKANG`,
      },
      {
        content: `DESKRIPSI`,
      },
      {
        content: `NAMA ATRIBUT`,
      },
      {
        content: `BERAT ATRIBUT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT PAKAI`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `SUSUT PAKAI`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `ONGKOS`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `ST SELESAI`,
       
      },
      {
        content: `ST SERAH`,
       
      },
    ],
  ];
  data.forEach((element) => {
  
    let space = [
      {
        content :"",
        colSpan: 10,
        styles: {
          halign: "left",
        
        },
      },
    
     
    ];
    tableRows.push(space);
    let header = [
      {
        content: "No Pesanan : ",
        styles: {
          halign: "right",
        },
      },
      {
        content: element.no_so_pesanan,
      },
      {
        content: "Kategori : " + element.kode_kategori,
      },
    ];

    tableRows.push(header);

    let saldo = [
      {
        content: "Saldo Awal",
        styles: {
          halign: "right",
        },
      },
      {
        content: element.saldo_awal.toLocaleString("id-ID"),
      },
     
    ];
    tableRows.push(saldo);
    let lineheader = [
      {
        content: "__________________________________________________________________________________________________________________________________________________________________________",
        colSpan: 10,
        styles: {
          halign: "left",
        
        },
      },
    
     
    ];
    tableRows.push(lineheader);

    element.pesanan.forEach(row => {
      let body = [
        row.no_pesanan,
        row.kode_tukang,
        row.nama_barang,
        row.nama_atribut,
        {
          content : parseFloat(row.berat_atribut),
          styles: {
            halign: "right",
          },
        },
        {
          content : parseFloat(row.berat_pakai),
          styles: {
            halign: "right",
          },
        },
        {
          content : parseFloat(row.susut_pakai),
          styles: {
            halign: "right",
          },
        },
        {
          content : parseInt(row.ongkos).toLocaleString('kr-Ko'),
          styles: {
            halign: "right",
          },
        },
        row.st_selesai,
        row.st_serah,
      ];
      
      tableRows.push(body);
    });
    let foot = [
      {
        content: "__________________________________________________________________________________________________________________________________________________________________________",
        colSpan: 10,
        styles: {
          halign: "left",
        },
      },
    ];
    tableRows.push(foot);
    let tot = [
      {
        content :'',
        colSpan: 4,
        styles: {
          halign: "right",
        },
      },
      {
        content : element.pesanan.reduce((a,b)=>parseFloat(a) + parseFloat(b.berat_atribut),0),
        styles: {
          halign: "right",
        },
      },
      {
        content : element.pesanan.reduce((a,b)=>parseFloat(a) + parseFloat(b.berat_pakai),0),
        styles: {
          halign: "right",
        },
      },
      {
        content : element.pesanan.reduce((a,b)=>parseFloat(a) + parseFloat(b.susut_pakai),0),
        styles: {
          halign: "right",
        },
      },
      {
        content : element.pesanan.reduce((a,b)=>parseInt(a) + parseInt(b.ongkos),0).toLocaleString('kr-Ko'),
        styles: {
          halign: "right",
        },
      },
     
    ];
    tableRows.push(tot);
  });
  let pemisah = [
    {
      content: "__________________________________________________________________________________________________________________________________________________________________________",
      colSpan: 10,
      styles: {
        halign: "left",
      },
    },
  ];
  tableRows.push(pemisah);

  let hasil = data.map((list)=>{
    let jml = parseFloat(list.saldo_awal) - parseFloat(list.berat_pakai) - parseFloat(list.susut_pakai);

    return jml.toLocaleString("id-ID")

  })
  let printed = [
    {
      content: `Printed By ${JSON.parse(localStoragedecryp("userdata")).user_id} / ${getToday()}`,
      colSpan: 5,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
    {
      content: `Sisa Bahan : ${hasil}`,
      styles: {
        // lineWidth: 0.1,
        halign: "center",
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ]
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: tbl_barang,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // footRows = [];
  // tbl_barang = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     JSON.parse(localStoragedecryp("userdata")).user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN PESANAN SO.pdf`);
  // var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  // var x = window.open();
  // x.document.open();
  // x.document.write(
  //   "<html><head><title>LAPORAN PESANAN SO</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
  //     string +
  //     "></embed></body></html>"
  // );
  // x.document.write(embed);
  // // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPesananSo;
