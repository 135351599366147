import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanPesananTukangExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="LAPORAN PESANAN TUKANG"
          sheet="LAPORAN PESANAN TUKANG"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="9" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN PESANAN TUKANG{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> Periode </th>
            </tr>
            <tr>
              <th colSpan="9">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : JSON.parse(localStoragedecryp("tgl_laporan")).tgl_awal +
                    " s/d " +
                    JSON.parse(localStoragedecryp("tgl_laporan"))
                      .tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="9"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO PERSEKOT
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>TANGGAL</td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
            NAMA CUSTOMER
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NAMA BARANG
              </td>
              <td style={{backgroundColor: "#E8E5E5",color: "#000",}}>
                ATRIBUT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT ATR
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT CAD
              </td>
              <td style={{backgroundColor: "#E8E5E5",color: "#000",}}>
                TANGGAL SELESAI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT CUSTOMER
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT TUKANG
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ONGKOS CUSTOMER
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ONGKOS TUKANG
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SUSUT TUKANG
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ASAL BAHAN
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SALDO BAHAN
              </td>
              <td style={{backgroundColor: "#E8E5E5",color: "#000",}}>
                STATUS
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.dataexel.map((row, index) => (
                <tr key={index}>
                  <td>{row.no_persekot}</td>
                  <td>{row.tgl_system}</td>
                  <td>{row.nama_customer}</td>
                  <td>{row.nama_barang}</td>
                  <td>{row.nama_atribut}</td>
                  <td style={{ textAlign: "right" }}>
                    {row.berat_atr.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.berat_cad.toLocaleString("kr-KO")}
                  </td>
                  <td>
                    {row.tgl_selesai.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.berat_customer.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.berat_tukang.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.ongkos_customer.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.ongkos.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.susut_tukang.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.asal_bahan.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.saldo_bahan.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.status.toLocaleString("kr-KO")}
                  </td>
                </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="5"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                Grand Total :
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.berat_atr)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.berat_cad)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.berat_customer)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.berat_tukang)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.ongkos_customer)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.ongkos)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.susut_tukang)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.asal_bahan)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.dataexel
                  .map((list) => list.saldo_bahan)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanPesananTukangExel;
