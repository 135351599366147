import React, { Component } from "react";

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
// import { getDataBaki } from "../../../../actions/datamaster_action";
import {
  ReanderField,
  ReanderSelect,
} from "../../../../components/helpers/field";
import Tabel from "../../../../components/helpers/tabel";
import { getData } from "../../../../config/axios";
// import Tabel from "../../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import LaporanSaldoAktifKirimbangBosExel from "./exel/LaporanSaldoAktifKirimbangBosExel";
import LaporanPdfKirimbarangbosAktif from "./pdf/LaporanPdfKirimbarangbosAktif";

class HeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      tujuan: [],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf(data) {
    LaporanPdfKirimbarangbosAktif(data);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount() {
    getData("baki/get/by-kode-gudang/KC").then((res) => {
      let databaki = [
        {
          value: "ALL",
          name: "SEMUA",
        },
      ];
      res.data.forEach((list) => {
        let row = {
          value: list.kode_baki,
          name: list.kode_baki,
        };
        databaki.push(row);
      });

      this.setState({
        tujuan: databaki,
      });
    });

    this.props.change("kode_labeling","ALL")
  }
  render() {
    const columns = [
      {
        dataField: "no_kirim_bos",
        text: "No Kirim Bos",
        footerAttrs: {
          colSpan: "2",
        },
        footer: "",
      },
      {
        dataField: "kode_labeling",
        text: "Kode Labeling",
      },
      {
        dataField: "berat_awal",
        text: "Berat Awal",
        headerClasses: "text-right",
        formatter: (data) => {
          return <div className="text-right"> {data.toFixed(3)}</div>;
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
          </div>
        ),
      },

      {
        dataField: "berat_input",
        text: "Berat Input",
        headerClasses: "text-right",
        formatter: (data) => {
          return <div className="text-right"> {data.toFixed(3)}</div>;
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
          </div>
        ),
      },
      {
        dataField: "berat_sisa",
        text: "Berat Sisa",
        headerClasses: "text-right",
        formatter: (data) => {
          return <div className="text-right"> {data.toFixed(3)}</div>;
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
          </div>
        ),
      },
    ];
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="no_kirim_bos"
              component={ReanderField}
              label="No Kirim Bos"
              type="text"
              placeholder="Masukan No Kirim Bos"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="kode_labeling"
              component={ReanderSelect}
              options={this.state.tujuan}
              type="text"
              label="Kode Labeling"
              placeholder="Masukan Kode Labeling"
            />
          </div>

          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="no_kirim_bos"
              data={this.props.export ? this.props.dataterimabarang : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Saldo Aktif Kirim Barang Dari Bos"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf(this.props.dataterimabarang)}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanSaldoAktifKirimbangBosExel
                  data={this.props.dataterimabarang}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

HeadForm = reduxForm({
  form: "HeadForm",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadForm);
export default connect(null)(HeadForm);
// export default connect((state) => {
//   return {
//     databaki: state.datamaster.getDataBaki,
//   };
// })(HeadForm);
